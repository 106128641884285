import {
  DashboardGetters,
  DashboardState,
  DashboardStoreContext,
} from "@@/stores/modules/DashboardTypes.ts";
import {
  CompletedPortCallType,
  PortType,
  UpcomingPortCallType,
  VesselManagersFilterType,
  VesselType,
} from "@@/PortCallsTypes.ts";
import DashboardStatisticsController from "@/controllers/dashboard/DashboardStatisticsController.ts";

const getters: DashboardGetters = {
  getCount(this: DashboardStoreContext, state: DashboardState): number {
    return state.counter;
  },
  getPorts(this: DashboardStoreContext, state: DashboardState): PortType[] {
    return state.ports;
  },
  getVessels(this: DashboardStoreContext, state: DashboardState): VesselType[] {
    return state.vessels.map((vessel) => ({
      ...vessel,
      vessel_managers: vessel.vessel_managers?.map((vessel_manager) => ({
        ...vessel_manager,
        vessel_manager_name: (vessel_manager.first_name || vessel_manager.last_name
          ? `${vessel_manager.first_name} ${vessel_manager.last_name}`
          : `${vessel_manager.email}`
        ).trim(),
      })),
    }));
  },
  getVesselManagers(this: DashboardStoreContext): Array<VesselManagersFilterType> {
    const vesselsString = DashboardStatisticsController.filters.vessels as string;
    const vesselIDs = vesselsString
      ? vesselsString.split(",")
      : this.vessels.map((vessel) => vessel.id);

    const vesselsByID = this.getVessels.reduce((acc, _vessel) => {
      acc[_vessel.id] = _vessel;
      return acc;
    }, {} as Record<number, VesselType>);

    return vesselIDs.reduce((_vesselManagers, _vesselID) => {
      const _vessel = vesselsByID[_vesselID];
      if (_vessel) {
        _vesselManagers = [..._vesselManagers, ...(_vessel.vessel_managers ?? [])];
      }
      return _vesselManagers;
    }, [] as Array<VesselManagersFilterType>);
  },
  getUpcomingPortCalls(
    this: DashboardStoreContext,
    state: DashboardState,
  ): Array<UpcomingPortCallType> {
    return state.upcoming_port_calls;
  },
  getUpcomingPortCallsApproveCount(
    state: DashboardState,
  ): DashboardState["upcoming_port_calls_approve_count"] {
    return state.upcoming_port_calls_approve_count;
  },
  getUpcomingPortCallsTotalCount(
    state: DashboardState,
  ): DashboardState["upcoming_port_calls_total_count"] {
    return state.upcoming_port_calls_total_count;
  },
  getCompletedPortCalls(
    this: DashboardStoreContext,
    state: DashboardState,
  ): Array<CompletedPortCallType> {
    return state.completed_port_calls;
  },
  getCompletedPortCallsApproveCount(
    state: DashboardState,
  ): DashboardState["completed_port_calls_approve_count"] {
    return state.completed_port_calls_approve_count;
  },
  getCompletedPortCallsTotalCount(
    state: DashboardState,
  ): DashboardState["completed_port_calls_total_count"] {
    return state.completed_port_calls_total_count;
  },
  getCustomers(this: DashboardStoreContext, state: DashboardState): DashboardState["customers"] {
    return state.customers;
  },
};

export default getters;
