import { defineStore } from "pinia";
import state from "@/stores/dashboard/dashboard.state";
import getters from "@/stores/dashboard/dashboard.getters";
import actions from "@/stores/dashboard/dashboard.actions";

import { DashboardStoreDefinition } from "@@/stores/modules/DashboardTypes.ts";

const useDashboardStore: DashboardStoreDefinition = defineStore("dashboard", {
  state,
  getters,
  actions,
});

export default useDashboardStore;
