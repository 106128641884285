<template>
  <Dialog
    id="base-auth-message-dialog"
    v-model:visible="visible"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :closable="false"
    :draggable="false"
    :style="{ width: '30rem' }"
    modal
  >
    <template #header>
      <slot name="header">
        <div class="inline-flex gap-4">
          <div class="flex m-auto">
            <WilhelmsenLogo />
          </div>
          <div class="port-service-title">Port Services</div>
        </div>
      </slot>
    </template>
    <div class="py-5">
      <div>
        <slot name="title"></slot>
        {{ showLoadingString ? loadingString : "" }}
      </div>
      <slot name="description"></slot>
    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue";

import Dialog from "primevue/dialog";

import WilhelmsenLogo from "@/components/icons/WilhelmsenLogo.vue";

// import { isAgent } from "@/permission.ts";

const props = defineProps({
  showLoadingString: {
    type: Boolean,
    default: false,
  },
  loadingStringTimer: {
    type: Number,
    default: 400,
  },
});

const visible = ref(true);
const interval = ref<ReturnType<typeof setInterval>>();
const loadingString = ref("");

const setLoadingString = () => {
  const dots = ["", ".", "..", "..."];
  let i = 0;

  interval.value = setInterval(() => {
    if (i == 3) {
      i = 0;
    } else {
      i++;
    }

    loadingString.value = dots[i];
  }, props.loadingStringTimer || 500);
};

onMounted(() => {
  if (props.showLoadingString) {
    setLoadingString();
  }
});

onUnmounted(() => {
  clearInterval(interval.value);
});
</script>
