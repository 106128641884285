import { ServiceTypeNameEnum } from "@@/PortCallsTypes.ts";

export type ChartDataType = {
  labels: string[];
  datasets: {
    label: string;
    data: (number | null)[];
    backgroundColor: string;
    borderColor?: string;
    borderWidth?: number | object;
    borderRadius?:
      | number
      | number[]
      | { topLeft: number; topRight: number; bottomLeft: number; bottomRight: number };
    borderSkipped?: boolean;
    stack: STACKS;
    yAxisID: string;
    barThickness?: number;
    maxBarThickness?: number;
    barPercentage?: number;
    categoryPercentage?: number;
    datalabels?: {
      color: string;
    };
  }[];
};

export enum STACKS {
  cost = "cost",
  emission = "emission",
}

export type ChartStackType = Record<
  STACKS,
  {
    label: STACKS;
    count: number;
  }
>;

export type CostAndEmissionsPerPortResponseType = {
  labels: string[];
  datasets: Record<STACKS, Record<keyof typeof ServiceTypeNameEnum, Array<number>>>;
};

export type CostAndEmissionsPerPortSingleStack = {
  labels: string[];
  datasets: Record<STACKS, Array<number>>;
};

export type TotalMonthlyCostAndEmissionsResponseType = {
  labels: string[];
  datasets: Record<STACKS, Array<number | null>>;
};

export type ChartParamsRequestType = {
  from_date: string;
  to_date: string;
  port__in: string;
  vessel__in: string;
  vessel_manager__in: string;
};
