/**
 * Import dependencies
 */
import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";

/**
 * Import stores
 */
import { useSiteStateStore } from "@/stores";

/**
 * Import types
 */
import type { RouteRecordRaw } from "vue-router";

/**
 * Import routes
 */
import dashboardRoutes from "@/views/dashboard/dashboard-routes";
import statisticsRoutes from "@/views/statistics/statistics-routes.ts";
import errorRoutes from "@/views/errors/error-routes";
import authRoutes from "@/views/auth/auth-routes";
import themeRoutes from "@/theme/theme-routes";
import portCallRoutes from "@/views/port-call/port-call-routes.ts";
import designsPreRoutes from "@/views/designs-pre/designs-pre-routes.ts";

/**
 * Import controllers
 */
import LoaderController from "@/controllers/LoaderController.ts";

/**
 * Import services
 */
import { auth0, isLoggedIn } from "@/services/auth.service.ts";

/**
 * Import utilities
 */
import helper from "@/Helper.ts";
import {
  checkBackendStateAndActAccording,
  setCurrentModuleName,
  setUserRole,
} from "@/utilities.ts";

/**
 * Import views
 */
import HomeView from "@/views/HomeView.vue";
import AuthController from "@/controllers/AuthController.ts";

/**
 * Define routes
 */
const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  ...authRoutes,
  ...dashboardRoutes,
  ...statisticsRoutes,
  ...portCallRoutes,
  ...designsPreRoutes,
  ...errorRoutes,
  ...themeRoutes,
];

/**
 * Create router
 */
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach(async (to, __, next) => {
  if (
    to.query?.state &&
    to.query?.clearLocalStorage &&
    checkBackendStateAndActAccording(to, next)
  ) {
    AuthController.checkingBackendState = false;
    return;
  }
  AuthController.checkingBackendState = false;

  LoaderController.showLoader();
  const siteStateStore = useSiteStateStore();

  siteStateStore.setIsContainer(true);

  if (!to?.meta?.isPublic && !isLoggedIn()) {
    await auth0
      .getAccessTokenSilently()
      .then()
      .catch(() => {
        const intended = JSON.stringify(window.location.pathname + window.location.search);
        const _intended = localStorage.getItem("intended");
        localStorage.setItem("intended", _intended ?? intended);
        next({ name: "Login" });
      });
  }

  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    siteStateStore.titleReplace = {};
    helper.updatePageTitle([to?.meta?.titleDefault ?? to?.meta?.title]);
  }).then();

  next();
});

/**
 * Set page title
 */
router.afterEach(() => {
  LoaderController.hideLoader();
  setCurrentModuleName();
  setUserRole();
});

/**
 * Export router
 */
export default router;
