import "./assets/scss/main.scss";

import { createApp } from "vue";
import { createPinia } from "pinia";

import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import Ripple from "primevue/ripple";
import { auth0 } from "@/services/auth.service";
import { initChartJS } from "@/utilities/_chart.ts";

import VueJsonPretty from "vue-json-pretty";
import BaseJsonPretty from "@/components/reusables/BaseJsonPretty.vue";

import router from "@/router";
import i18n from "@/i18n";

import App from "@/App.vue";

initChartJS();

export const app = createApp(App);
// Plugins
app.use(createPinia());
app.use(i18n);
app.use(router); // Router must be used before any other plugin that relies on router hooks
app.use(auth0);
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(ToastService);

// Directive
app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);
app.directive("visible", {
  mounted(el, binding) {
    el.style.visibility = binding.value ? "visible" : "hidden";
  },
  updated(el, binding) {
    el.style.visibility = binding.value ? "visible" : "hidden";
  },
});

// Global components
app.component("VueJsonPretty", VueJsonPretty);
app.component("BaseJsonPretty", BaseJsonPretty);

// Mount app
app.mount("#app");
