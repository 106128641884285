import { ref } from "vue";
import { createAuth0 } from "@auth0/auth0-vue";

import AuthController from "@/controllers/AuthController.ts";
import { encodeJWT } from "@/utilities.ts";
import { secondsToMilliseconds } from "date-fns";

const loginCheckInterval = ref(secondsToMilliseconds(60));
const authorizationParams = ref({
  redirect_uri: `${window.location.origin}/${import.meta.env.VITE_AUTH0_REDIRECT_URL}`,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
});

export const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  authorizationParams: authorizationParams.value,
  useCookiesForTransactions: true,
  useRefreshTokens: true,
  useRefreshTokensFallback: false,
});

export const checkIfUserIsAuthenticatedInterval = ref<ReturnType<typeof setInterval>>();

export const login = (keepSelectedCustomer = false) => {
  clearInterval(checkIfUserIsAuthenticatedInterval.value);
  cleanLocalStorage(keepSelectedCustomer);

  console.log("login(): ", getIntended());

  auth0?.loginWithRedirect({
    appState: { target: getIntended() },
  });
};

export const afterAction = () => {
  localStorage.removeItem("intended");
  localStorage.removeItem("intended_source");
};

export const getIntended = () => {
  const intended = localStorage.getItem("intended");
  return (intended ? JSON.parse(intended) : "/") as string;
};
export const getIntendedSource = () => {
  const intended_source = localStorage.getItem("intended_source");
  return (intended_source ? JSON.parse(intended_source) : "platform13") as string;
};

export const isLoggedIn = () => {
  return auth0.isAuthenticated.value;
};

export const logout = async () => {
  clearInterval(checkIfUserIsAuthenticatedInterval.value);
  cleanLocalStorage();

  const _intended = getIntended();
  const _source = getIntendedSource();

  console.log("logout(): ------> ", _intended, _source);

  await auth0?.logout({
    logoutParams: {
      returnTo: `${window.location.origin}`,
    },
  });

  if (_source === "admin") {
    localStorage.removeItem("intended");
    localStorage.removeItem("intended_source");

    window.location.replace(_intended);

    console.log("_intended", _intended);
    return;
  }

  const scheme = import.meta.env.VITE_APP_API_SCHEME;
  const domain = import.meta.env.VITE_APP_API_URL.split("/api")[0];
  const logoutPath = import.meta.env.VITE_APP_ADMIN_LOGOUT;

  const intended = `${window.location.origin}${_intended}`;
  const source = "platform13";
  const email = auth0.user.value?.email;
  const state = await encodeJWT({
    email,
    intended,
    source,
  });

  const fullUrl = `${scheme}${domain}/${logoutPath}/?state=${state}`;
  window.location.replace(fullUrl);
  return;
};

export const checkIfUserIsAuthenticated = () => {
  if (checkIfUserIsAuthenticatedInterval.value) {
    return;
  }

  checkIfUserIsAuthenticatedInterval.value = setInterval(async () => {
    if (auth0.isLoading.value || AuthController.checkingBackendState) {
      return;
    }

    getAccessToken().catch(() => {
      console.log("Failed to refresh tokens...");
      login();
    });
  }, loginCheckInterval.value);
};

export const getAuth0Email = () => auth0.user.value?.email;

const getAccessToken = async (): Promise<string> => {
  return await auth0.getAccessTokenSilently();
};

const cleanLocalStorage = (keepSelectedCustomer = false) => {
  localStorage.removeItem("user");
  localStorage.removeItem("idToken");
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("idTokenClaims");
  localStorage.removeItem("expireAt");

  if (!keepSelectedCustomer) {
    localStorage.removeItem("customer_id");
    localStorage.removeItem("selectedCustomer");
  }
};
