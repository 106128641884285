import { useAxios } from "@/services/axios.service.ts";

import { HTTP_DATA } from "@/api/port-call/httpEnums.ts";
import {
  ApprovePortCallRequestType,
  ExpenseGroupLineOptionsRequestType,
  SupplementaryDataRequestType,
} from "@@/PortCallsTypes.ts";
import { templateMultiStringReplace } from "@/utilities.ts";
import { generalObjectType } from "@@/CommonTypes.ts";

const fetchPortCallByID = async (da_id: string) =>
  useAxios().get(`${HTTP_DATA.GET_PORT_CALL_BY_ID}/${da_id}`);

const patchPortCallByID = async (da_id: string, data: ApprovePortCallRequestType) =>
  useAxios().patch(`${HTTP_DATA.GET_PORT_CALL_BY_ID}/${da_id}`, data);

const getPortCallAdditionalInfoByID = async (id: number) => {
  console.log(
    "getPortCallAdditionalInfoByID",
    `${HTTP_DATA.GET_PORT_CALL_ADDITIONAL_INFO_BY_ID}`,
    id,
  );

  console.log("-------------");
  console.log(
    templateMultiStringReplace(
      {
        id: id.toString(),
      },
      `${HTTP_DATA.GET_PORT_CALL_ADDITIONAL_INFO_BY_ID}`,
    ),
  );
};

const getPortCallOptionsByID = async (id: number) => {
  return useAxios().get(
    templateMultiStringReplace(
      {
        id: id.toString(),
      },
      `${HTTP_DATA.GET_PORT_CALL_OPTIONS_BY_ID}`,
    ),
  );
};

const getPortCallSupplementaryDataByDAID = async (params: generalObjectType) => {
  const headers = {};
  const showLoader = !!(params?.showLoader ?? true);
  delete params?.showLoader;
  if (!showLoader) {
    headers["showLoader"] = false;
  }

  return useAxios().get(
    templateMultiStringReplace(params, `${HTTP_DATA.GET_PORT_CALL_SUPPLEMENTARY_DATA_BY_DA_ID}`),
    {
      headers,
    },
  );
};

const getPortCallExpenseGroupLineOptionByDAID = async (params: generalObjectType) => {
  const headers = {};
  const showLoader = !!(params?.showLoader ?? true);
  delete params?.showLoader;
  if (!showLoader) {
    headers["showLoader"] = false;
  }

  return useAxios().get(
    templateMultiStringReplace(
      params,
      `${HTTP_DATA.GET_PORT_CALL_EXPENSE_GROUP_LINE_OPTION_BY_DA_ID}`,
    ),
    {
      headers,
    },
  );
};

const savePortCallSupplementaryData = async (param: SupplementaryDataRequestType) => {
  return useAxios().post(HTTP_DATA.CREATE_PORT_CALL_SUPPLEMENTARY, param);
};

const savePortCallActualInfo = async (
  da_id: string,
  isSaveAsDraft: boolean,
  param: ExpenseGroupLineOptionsRequestType,
) => {
  return useAxios().post(
    templateMultiStringReplace({ da_id }, HTTP_DATA.CREATE_PORT_CALL_ACTUAL_INFO) +
      `?isSaveAsDraft=${isSaveAsDraft}`,
    param,
  );
};

const downloadFDACSV = async (params?: generalObjectType) => {
  params = params ?? {};
  const headers = {};
  const showLoader = !!(params?.showLoader ?? true);
  delete params?.showLoader;
  if (!showLoader) {
    headers["showLoader"] = false;
  }

  return useAxios().get(templateMultiStringReplace(params, `${HTTP_DATA.DOWNLOAD_FDA_CSV}`), {
    headers,
  });
};

export {
  fetchPortCallByID,
  patchPortCallByID,
  getPortCallAdditionalInfoByID,
  getPortCallOptionsByID,
  getPortCallSupplementaryDataByDAID,
  getPortCallExpenseGroupLineOptionByDAID,
  savePortCallSupplementaryData,
  savePortCallActualInfo,
  downloadFDACSV,
};
