import { ToastSeverity } from "primevue/api";
import { app } from "@/main";
import { ToastMessageOptions } from "primevue/toast";

const lifeTime = 3000;

/**
 * @description Toast services
 * @param {string} title - Title of the toast
 * @param {string} body - Body of the toast
 * @param {number} lifetime - Toast duration in milliseconds
 */
export function success(title: string, body: string, lifetime?: number): void {
  app.config.globalProperties.$toast.add({
    severity: ToastSeverity.SUCCESS as ToastMessageOptions["severity"],
    summary: title,
    detail: body,
    life: lifetime ?? lifeTime,
  });
}

/**
 * @description Toast services
 * @param {string} title - Title of the toast
 * @param {string} body - Body of the toast
 * @param {number} lifetime - Toast duration in milliseconds
 */
export function info(title: string, body: string, lifetime?: number): void {
  app.config.globalProperties.$toast.add({
    severity: ToastSeverity.INFO as ToastMessageOptions["severity"],
    summary: title,
    detail: body,
    life: lifetime ?? lifeTime,
  });
}

/**
 * @description Toast services
 * @param {string} title - Title of the toast
 * @param {string} body - Body of the toast
 * @param {number} lifetime - Toast duration in milliseconds
 */
export function warning(title: string, body: string, lifetime?: number): void {
  app.config.globalProperties.$toast.add({
    severity: ToastSeverity.WARN as ToastMessageOptions["severity"],
    summary: title,
    detail: body,
    life: lifetime ?? lifeTime,
  });
}

/**
 * @description Toast services
 * @param {string} title - Title of the toast
 * @param {string} body - Body of the toast
 * @param {number} lifetime - Toast duration in milliseconds
 */
export function error(title: string, body: string, lifetime?: number): void {
  app.config.globalProperties.$toast.add({
    severity: ToastSeverity.ERROR as ToastMessageOptions["severity"],
    summary: title,
    detail: body,
    life: lifetime ?? lifeTime,
  });
}
