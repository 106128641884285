<template>
  <svg
    :class="[sortClass]"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ArrowDropDownFilled">
      <path id="Vector" :fill="fill" :fill-opacity="fillOpacity" d="M7 9.5L12 14.5L17 9.5H7Z" />
    </g>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = defineProps({
  fill: {
    type: String,
    default: "black",
  },
  fillOpacity: {
    type: String,
    default: "0.54",
  },
  sorted: {
    type: Boolean,
    default: false,
  },
  sortorder: {
    type: Number,
    default: 0,
  },
});

const sortClass = computed(() => {
  if (!props.sorted) {
    return "";
  }

  switch (props.sortorder) {
    case -1:
      return "rotate-0 p-highlight";
    case 1:
      return "rotate-180 p-highlight";
    case 0:
    default:
      return "rotate-180";
  }
});
</script>
