import type { RouteRecordRaw } from "vue-router";

import DashboardLayout from "@/layouts/DashboardLayout.vue";
import StatusSub from "@/components/pages/port-call/StatusSub.vue";
import PortCallAdditionalDataController from "@/controllers/port-call/PortCallAdditionalDataController.ts";

const routes: RouteRecordRaw[] = [
  {
    path: "/:status",
    component: DashboardLayout,
    redirect: {
      name: "StatusPortCallAdditionalForm",
    },
    children: [
      {
        path: "port-call",
        component: StatusSub,
        redirect: {
          name: "StatusPortCallAdditionalForm",
        },
        children: [
          {
            path: ":da_id",
            name: "StatusPortCallID",
            component: () => import("@/views/port-call/PortCallDetailsView.vue"),
            meta: {
              title: "{status} - {status_title} Port Call | {vessel_name}",
              titleDefault: "Port Call Details",
            },
          },
          {
            path: "add",
            name: "StatusPortCallAdditionalForm",
            component: () => import("@/views/port-call/PortCallAddView.vue"),
            meta: {
              title: "{status_title} Port Call - {step_x_title}",
              titleDefault: PortCallAdditionalDataController.headerTitleStep1,
            },
          },
        ],
      },
    ],
  },
];

export default routes;
