import { ref } from "vue";

import enUS from "./en-US.json";
import daDK from "./da-DK.json";
import noNO from "./no-NO.json";
import svSE from "./sv-SE.json";

export const defaultLocal = import.meta.env.VITE_APP_I18N_LOCALE || "en-US";

export const messages = ref({
  "en-US": enUS,
  "no-NO": noNO,
  "da-DK": daDK,
  "sv-SE": svSE,
});

export const availableLocales = ref<string[]>(Object.keys(messages.value));
