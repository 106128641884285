import { User } from "@auth0/auth0-vue";

export type UserType = User;

// Ref: https://auth0.com/docs/get-started/apis/scopes/sample-use-cases-scopes-and-claims#add-custom-claims-to-a-token
export enum USER_ROLES {
  system_administrator = "System Administrator",
  agent = "Agent",
  vessel_manager = "Vessel Manager",
  esg_manager = "ESG Manager",
  "System Administrator" = "system_administrator",
  Agent = "agent",
  "Vessel Manager" = "vessel_manager",
  "ESG Manager" = "esg_manager",
}
