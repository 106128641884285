import { DashboardState } from "@@/stores/modules/DashboardTypes.ts";

// use snake_case for state keys
export default (): DashboardState => ({
  counter: 0,
  ports: [],
  vessels: [],
  vessel_managers: [],
  upcoming_port_calls: [],
  upcoming_port_calls_response: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  upcoming_port_calls_approve_count: 0,
  upcoming_port_calls_total_count: 0,
  completed_port_calls: [],
  completed_port_calls_response: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  completed_port_calls_approve_count: 0,
  completed_port_calls_total_count: 0,
  customer_id: null,
  customers: [],
  customers_response: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  show_select_customer_dialog: false,
  can_access_customer: false,
});
