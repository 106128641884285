import { ref } from "vue";

import type { MenuItem } from "primevue/menuitem";
import type { RouteLocationNormalizedLoaded, RouteRecordRaw } from "vue-router";
import { generalObject } from "@@/CommonTypes.ts";

export default class Helper {
  static getMenu() {
    const menuItems = ref<Array<MenuItem>>([]);
    const vueFiles = {
      ...import.meta.glob("@/theme/*.vue"),
      ...import.meta.glob("@/theme/*/*.vue"),
    };
    const components = ref<{
      [p: string]: RouteRecordRaw["component"];
    }>({});

    Object.entries(vueFiles).forEach(([name, item]) => {
      const splits: Array<string> = (name as unknown as string)?.split("/") ?? [];
      const component: string = splits.at(-1)?.split("Component.vue")?.at(0) as string;
      const componentLowerCase = component?.toLowerCase();
      components.value[componentLowerCase] = item;

      menuItems.value.push({
        label: component.charAt(0).toUpperCase() + component.slice(1),
        icon: "pi pi-fw pi-circle-on",
        to: `/theme/${componentLowerCase}`,
      });
    });

    return { menuItems, vueFiles, components };
  }

  static makeFloatToFixed(value: number | string, toFixed = 2) {
    return parseFloat((value || 0).toString()).toFixed(toFixed);
  }

  static getPageTitle(titles: unknown[], join = " | "): string {
    return [...titles, import.meta.env.VITE_APP_TITLE].filter((item) => item).join(join);
  }

  static setPageTitle(title: string) {
    document.title = title ?? document.title;
  }

  static updatePageTitle(titles: unknown[], join = " | ") {
    this.setPageTitle(this.getPageTitle(titles, join));
  }

  static replacePageTitle(to: RouteLocationNormalizedLoaded, replaceObj: generalObject<string>) {
    if (Object.keys(replaceObj)?.length) {
      this.setPageTitle(
        this.templateMultiStringReplace(
          replaceObj,
          this.getPageTitle([to?.meta?.title ?? to?.meta?.titleDefault]),
        ),
      );
    }
  }

  static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static templateMultiStringReplace<T extends string>(object: generalObject<string>, string: T): T {
    const entries = Object.entries(object);
    entries.forEach(([key, value]) => {
      const find = "{" + key + "}";
      const regExp = new RegExp(find, "g");
      string = string.replace(regExp, value as T) as T;
    });
    return string;
  }
}
