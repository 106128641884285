import type { RouteRecordRaw } from "vue-router";

import NotFound from "./E404View.vue";
import ErrorLayout from "@/layouts/ErrorLayout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/error",
    component: ErrorLayout,
    redirect: {
      name: "NotFound",
    },
    children: [
      {
        path: "404",
        name: "NotFound",
        component: NotFound,
        meta: {
          title: "404 - Not found",
          isPublic: true,
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorLayout,
    children: [
      {
        path: "/:pathMatch(.*)*",
        component: NotFound,
        name: "GeneralNotFound",
        meta: {
          title: "404 - Not found",
          isPublic: true,
        },
      },
    ],
  },
];

export default routes;
