<template>
  <Button
    aria-controls="overlay_menu"
    aria-haspopup="true"
    class="py-1 px-1"
    size="small"
    text
    type="button"
    @click="toggle"
  >
    <ProfileDropdownIcon />
  </Button>
  <Menu id="overlay_menu" ref="menu" :model="items2" :popup="true">
    <template #start>
      <button
        class="w-full p-link flex align-items-center p-2 pl-3 text-color hover:surface-200 border-noround gap-2"
        @click="profileClick"
      >
        <Avatar :image="user?.picture" class="" shape="circle" />
        <div
          :title="`${user?.nickname} - ${user?.email}`"
          class="flex flex-column align overflow-hidden flex-1"
        >
          <span class="font-bold overflow-hidden text-overflow-ellipsis">{{ user?.nickname }}</span>
          <span class="text-sm">{{ USER_ROLES?.[user?.user_role] ?? "-" }}</span>
        </div>
      </button>
    </template>
    <template #item="{ label, item, props }">
      <RouterLink v-if="item.route" #default="routerProps" :to="item.route" custom>
        <a v-bind="{ ...props.action, href: routerProps.href }" @click="routerProps.navigate">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
        </a>
      </RouterLink>
      <a v-else v-bind="{ ...props.action, href: item.url }">
        <span v-bind="props.icon" />
        <span v-bind="props.label">{{ label }}</span>
      </a>
    </template>
    <template #end>
      <button
        class="w-full p-link flex align-items-center p-2 pl-4 text-color hover:surface-200 border-noround"
        @click="logoutAuth0"
      >
        <i class="pi pi-sign-out" />
        <span class="ml-2">Log Out</span>
      </button>
    </template>
  </Menu>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import Menu from "primevue/menu";
import Avatar from "primevue/avatar";
import Button from "primevue/button";

import ProfileDropdownIcon from "@/components/icons/ProfileDropdownIcon.vue";
import { useRouter } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";

import { USER_ROLES } from "@@/AuthTypes.ts";

const router = useRouter();

const { user } = useAuth0();

const logoutAuth0 = () => {
  router.push({ name: "Logout" });
};

const items2 = ref([
  /*{ separator: true },
  {
    label: "Profile",
    icon: "pi pi-fw pi-user",
    route: {
      name: "Profile",
    },
  },
  { label: "Settings", icon: "pi pi-fw pi-cog" },*/
  /*{ separator: true },
  {
    label: "Statistics",
    icon: "pi pi-fw pi-chart-bar",
    route: {
      name: "Statistics",
    },
  },*/
  { separator: true },
]);

const profileClick = () => {
  console.log("profileClick", user.value);
};

const menu = ref();
const toggle = (event: MouseEvent) => {
  menu.value.toggle(event);
};
</script>
