import { TagProps } from "primevue/tag";

export type ChipMessageTypes =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "notice";

// django rest framework response pagination type
export type PaginationType<T> = {
  count: number;
  next: string;
  previous: string;
  results: T;
};

export type DebounceOptionsType = {
  callback: (options: DebounceOptionsType) => void;
  search?: string;
  after?: number;
};

export type OverlayPanelType = {
  type: ChipMessageTypes;
  label: string;
  hoverAction: boolean;
  overlay: string;
};

export type generalObject<T extends string | number> = {
  [key in T]: T;
};

// export type generalObjectType = { [key: string]: any };
export type generalObjectType = Record<string, any>;

export type CustomerType = {
  id: number;
  customer_name: string;
};

export type ResizeObserverOptionsType = {
  parentClass: string;
  iconClass: string;
  titleClass: string;
  after?: number;
};

export type ResponseDataType<T> = {
  data: T;
  status: number;
};

export type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type NumberFormatterOptionsType = {
  digits: number;
  commaSeparated: boolean;
  locales: Intl.LocalesArgument;
};

export type BackendAuth0State = {
  email: string;
};

export enum IntendedSourceEnum {
  admin = "admin",
  platform13 = "platform13",
}

// SSO = Single Sign On
export type SSOType = {
  intended: string; // intended url
  source: IntendedSourceEnum; // source url
  email: string;
};

export type CustomTagSeverity = TagProps["severity"] | "secondary" | "primary";
