<template>
  <section data-layout="dashboard">
    <header id="dashboard-header">
      <DashboardHeader :class="siteStateStore.isContainer ? 'container' : 'container-fluid'" />
    </header>

    <Teleport to="body">
      <Transition name="page-loader-fade">
        <PageLoader v-if="siteStateStore.isLoading" />
      </Transition>
    </Teleport>

    <main id="dashboard-main">
      <div :class="siteStateStore.isContainer ? 'container' : 'container-fluid'">
        <Message
          v-if="!isChrome && !isFirefox && firefoxVersion < minSupportedFirefoxVersion"
          severity="warn"
        >
          Please use latest
          <template v-if="!isFirefox">
            <a href="https://www.google.com/chrome/" rel="noopener" target="_blank">
              chrome - min v{{ minSupportedChromeVersion }}
            </a>
          </template>
          <template v-else>
            <a href="https://www.mozilla.org/en-US/firefox/new/" rel="noopener" target="_blank">
              firefox - min v{{ minSupportedFirefoxVersion }}
            </a>
          </template>
          browser for best experience.
        </Message>
      </div>
      <RouterView :class="siteStateStore.isContainer ? 'container' : 'container-fluid'" />

      <ConfirmDialog :closable="false" group="refreshRequire" />
    </main>
  </section>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { useTimestamp } from "@vueuse/core";

import Message from "primevue/message";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";

import { userAgentData } from "@/composables/useNavigator";
import { checkIfUserIsAuthenticated } from "@/services/auth.service.ts";

// State
import { useSiteStateStore } from "@/stores";

import DashboardHeader from "@/components/layouts/dashboard/DashboardHeader.vue";
import PageLoader from "@/components/common/PageLoader.vue";

const confirm = useConfirm();
const {
  isChrome,
  minSupportedChromeVersion,
  isFirefox,
  firefoxVersion,
  minSupportedFirefoxVersion,
} = userAgentData();
const siteStateStore = useSiteStateStore();
// Show refresh require dialog after 12 hours
const { timestamp, pause } = useTimestamp({
  controls: true,
  callback: () => {
    siteStateStore.setRefreshRequire(timestamp.value);

    if (siteStateStore.getRefreshRequire) {
      pause();
      refreshRequire();
    }
  },
});

onMounted(() => {
  checkIfUserIsAuthenticated();
  siteStateStore.setRefreshTimeout();
});

const refreshRequire = () => {
  confirm.require({
    group: "refreshRequire",
    message: "Do you want to refresh the page?",
    header: "Refresh is required",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    accept: () => {
      location.reload();
    },
    // reject: () => {},
  });
};
</script>
