<template>
  <div v-if="width <= 992" class="flex justify-content-end">
    <Sidebar v-model:visible="visible" :pt="{ root: { class: 'w-16rem' } }">
      <SideBarContent />
    </Sidebar>

    <Button icon="pi pi-bars" @click="visible = true"></Button>
  </div>
  <template v-else>
    <SideBarContent />
  </template>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import SideBarContent from "@/components/layouts/theme/SideBarContent.vue";
import { useBreakpoints } from "@/composables/useBreakpoints";

const visible = ref(false);
const { width } = useBreakpoints();
</script>
