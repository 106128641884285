import { StatisticsGetters, StatisticsStoreContext } from "@@/stores/modules/StatisticsTypes.ts";
import {
  CostAndEmissionsPerPortResponseType,
  CostAndEmissionsPerPortSingleStack,
  TotalMonthlyCostAndEmissionsResponseType,
} from "@@/ChartTypes.ts";

const getters: StatisticsGetters = {
  getCostAndEmissionsPerPort(this: StatisticsStoreContext): CostAndEmissionsPerPortResponseType {
    return this.$state.cost_and_Emissions_per_port;
  },
  getFDACostAndEmissionsPerPort(this: StatisticsStoreContext): CostAndEmissionsPerPortSingleStack {
    return this.$state.fda_cost_and_Emissions_per_port;
  },
  getCostAndEmissionsPerPortSingleStack(
    this: StatisticsStoreContext,
  ): CostAndEmissionsPerPortSingleStack {
    const datasets = Object.fromEntries(
      Object.entries(this.$state.cost_and_Emissions_per_port.datasets).map(([key, value]) => {
        const _value = Object.values(value).reduce((acc, row) => {
          row.forEach((value, index) => {
            const v = (acc[index] || 0) <= 0 ? 0 : acc[index];
            acc[index] = v + value;
          });
          return acc;
        }, []);

        return [key, _value];
      }),
    );

    return {
      labels: this.$state.cost_and_Emissions_per_port.labels,
      datasets,
    } as CostAndEmissionsPerPortSingleStack;
  },
  getTotalMonthlyCostAndEmissions(
    this: StatisticsStoreContext,
  ): TotalMonthlyCostAndEmissionsResponseType {
    return this.$state.total_monthly_cost_and_emissions;
  },
  getFDATotalMonthlyCostAndEmissions(
    this: StatisticsStoreContext,
  ): TotalMonthlyCostAndEmissionsResponseType {
    return this.$state.fda_total_monthly_cost_and_emissions;
  },
};

export default getters;
