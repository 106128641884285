import { DashboardActions, DashboardStoreContext } from "@@/stores/modules/DashboardTypes.ts";
import {
  fetchCompletedPortCalls,
  fetchCustomers,
  fetchPorts,
  fetchUpcomingPortCalls,
  fetchVesselManagers,
  fetchVessels,
} from "@/api/dashboard/dashboard.api.ts";
import {
  CompletedPortCallType,
  CustomersResponseType,
  Status,
  UpcomingPortCallType,
} from "@@/PortCallsTypes.ts";
import { CustomerType, generalObjectType, ResponseDataType } from "@@/CommonTypes.ts";
import { arrayUniqueByKey } from "@/utilities.ts";
import { auth0 } from "@/services/auth.service.ts";

// Since we rely on `this`, we cannot use an arrow function
const actions: DashboardActions = {
  increment(this: DashboardStoreContext) {
    this.$state.counter++;
  },
  decrement(this: DashboardStoreContext) {
    this.$state.counter--;
  },
  setCustomerID(this: DashboardStoreContext, customer?: CustomerType) {
    if (!customer?.id) {
      localStorage.removeItem("customer_id");
      localStorage.removeItem("selectedCustomer");
      return;
    }

    if (auth0?.user?.value?.can_access_customer) {
      this.$state.customer_id = customer.id;
      localStorage.setItem("customer_id", String(customer.id));
      localStorage.setItem("selectedCustomer", JSON.stringify(customer));
    }
  },
  async fetchPortsAction(this: DashboardStoreContext) {
    try {
      const { data, status } = await fetchPorts();
      /**
       * Sync the store after updated successfully
       */
      this.$state.ports = data;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchVesselsAction(this: DashboardStoreContext, params: generalObjectType = {}) {
    try {
      const { data, status } = await fetchVessels(params);
      /**
       * Sync the store after updated successfully
       */
      this.$state.vessels = data.results ?? data;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchVesselManagersAction(this: DashboardStoreContext) {
    try {
      const { data, status } = await fetchVesselManagers();
      /**
       * Sync the store after updated successfully
       */
      this.$state.vessel_managers = data;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchUpcomingPortCallsAction(this: DashboardStoreContext) {
    try {
      const { data, status } = await fetchUpcomingPortCalls();
      /**
       * Sync the store after updated successfully
       */
      this.upcoming_port_calls_response = data;
      const resultData = data?.results ?? data;
      this.upcoming_port_calls = resultData;

      this.upcoming_port_calls_approve_count =
        resultData?.total_approved ??
        resultData.filter((portCall: UpcomingPortCallType) => portCall.status === Status.APPROVED)
          .length;

      this.upcoming_port_calls_total_count = data?.count ?? resultData.length;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCompletedPortCallsAction(this: DashboardStoreContext) {
    try {
      const { data, status } = await fetchCompletedPortCalls();
      /**
       * Sync the store after updated successfully
       */
      this.completed_port_calls_response = data;
      const resultData = data?.results ?? data;
      this.completed_port_calls = resultData;

      this.completed_port_calls_approve_count =
        resultData?.total_approved ??
        resultData.filter((portCall: CompletedPortCallType) => portCall.status === Status.APPROVED)
          .length;

      this.completed_port_calls_total_count = data?.count ?? resultData.length;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCustomersAction<T extends CustomersResponseType>(
    this: DashboardStoreContext,
    params: generalObjectType = {},
  ) {
    try {
      const { data, status }: ResponseDataType<T> = await fetchCustomers(params);

      if (params?.page === 1 || !params?.page) {
        this.customers = data.results;
      } else {
        this.customers = arrayUniqueByKey([...this.customers, ...data.results], "id");
      }

      this.customers_response = data;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
};

export default actions;
