import { ref } from "vue";
import { fetchPortCallByID, patchPortCallByID } from "@/api/port-call/port-call.api.ts";

import { defaultPortCall } from "@/stores/port-call/port-call.state.ts";
import { useDashboardStore } from "@/stores";

import { CustomerType } from "@@/CommonTypes.ts";
import { PortCallActions, PortCallStoreContext } from "@@/stores/modules/PortCallTypes.ts";
import {
  EmissionReductionPotentialsType,
  PortCallFormType,
  PortCallType,
  Status,
  StatusSub,
  SupplierCompaniesType,
  SupplierCompanyType,
  SuppliersType,
} from "@@/PortCallsTypes.ts";

// Since we rely on `this`, we cannot use an arrow function
const actions: PortCallActions = {
  async approvePortCall(this: PortCallStoreContext, { da_id, param }) {
    try {
      const { data, status } = await patchPortCallByID(da_id, param);

      this.$state.portCall.suppliers = data.suppliers;
      this.$state.portCall.status = data.status ?? Status.APPROVED;

      // Ref: https://dev.azure.com/gture/Wilhelmsen%20Platform%2013/_boards/board/t/Wilhelmsen%20Platform%2013%20Team/Stories
      this.$state.portCall.abatement_cost = data.abatement_cost;
      this.$state.portCall.reduced_emissions = data.reduced_emissions;
      this.$state.portCall.potential_reduced_emissions_percentage =
        data.potential_reduced_emissions_percentage;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchPortCallByIDAction(this: PortCallStoreContext, { da_id }) {
    try {
      const { data, status } = await fetchPortCallByID(da_id);
      /**
       * Sync the store after updated successfully
       */
      const responseData = ref<PortCallType>(data);
      const dashboardStore = useDashboardStore();

      responseData.value.suppliers =
        responseData.value.suppliers ?? {} /*SupplierService.suppliers.value*/;

      /**
       * Here `responseData.value.additional_costs` is `[null]`.
       *
       * This one is an unfair one, API should not respond like this, never.
       * It should be `AdditionalCost[]` and `null` is totally unacceptable
       * where expecting an object of type {@link AdditionalCost}.
       *
       * Here is two possible solution for such data:
       *
       * _[Note: I found 2nd one is more suitable, as you never know if the
       * null value is the first one]_
       */
      /*responseData.value.additional_costs =
        responseData.value.additional_costs[0] === null
          ? []
          : responseData.value.additional_costs;
      responseData.value.additional_costs = responseData.value.additional_costs.filter(
        (cost: AdditionalCost) => !!cost?.expense_group_name,
      );*/

      this.portCall = responseData.value;

      /**
       * Note:
       *  How a `SupplierCompanyType` is selected by default?
       *  > PDA - A supplier company is selected by default if the company is the cheapest in cost.
       */
      const _defaultSuppliersCompany = JSON.parse(
        JSON.stringify(responseData.value.suppliers),
      ) as SuppliersType;
      this.defaultSuppliersCompany = Object.fromEntries(
        Object.entries(_defaultSuppliersCompany)
          .map(([key, supplierCompanies]) => {
            let _selectedCompany: SupplierCompanyType | SupplierCompaniesType =
              supplierCompanies.filter((company: SupplierCompanyType) => company.selected);

            _selectedCompany =
              this.portCall.status_sub === StatusSub.PDA_SUBMITTED
                ? _selectedCompany[0]
                : _selectedCompany;

            return [key, _selectedCompany];
          })
          .filter(([, supplierCompanies]) => !!supplierCompanies),
      );

      const customer = <CustomerType>{};
      customer.customer_name = responseData.value?.customer?.customer_name;
      customer.id = responseData.value?.customer?.id;
      dashboardStore.setCustomerID(customer);

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  clearPortCallFromStore(this: PortCallStoreContext, portCall = defaultPortCall) {
    this.portCall = portCall;
    this.portCallForm = portCall as unknown as PortCallFormType;
  },
  setDataSeed(this: PortCallStoreContext, value: boolean) {
    this.dataSeed = value;
  },
  setEmissionReductionPotentials(
    this: PortCallStoreContext,
    emissionReductionPotentials: EmissionReductionPotentialsType,
  ) {
    this.$state.emissionReductionPotentials = emissionReductionPotentials;
  },
};

export default actions;
