<template>
  <div ref="authViewRef">
    <div v-if="router.currentRoute.value.query.error">
      <h1>{{ router.currentRoute.value.query.error }}</h1>
      <p>{{ router.currentRoute.value.query.error_description }}</p>
    </div>
    <div v-else>
      <BaseAuthMessageDialog ref="baseAuthMessageDialogRef" :show-loading-string="true">
        <template #title>
          <span>
            {{ authMessageTitle }}
          </span>
        </template>
        <template #description>
          <template v-if="router.currentRoute.value.name == `Login`"></template>
          <template v-if="router.currentRoute.value.name == `Logout`">
            <br />
            <small>
              If you are not redirected in {{ redirectAfter_sec }} seconds, <br />please click
              <a v-bind="{ href: state.intended }">here</a> to go back to the home page.
            </small>
          </template>
          <template v-if="router.currentRoute.value.name === `Auth0Redirect`">
            <br />
            <small>
              If you are not redirected in {{ redirectAfter_sec }} seconds, <br />please click
              <RouterLink to="/">here</RouterLink>
              to go back to the home page.
            </small>
          </template>
        </template>
      </BaseAuthMessageDialog>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import { jwtDecode } from "jwt-decode";

import {
  afterAction,
  getIntended,
  login,
  logout,
} from "@/services/auth.service.ts";

import { IntendedSourceEnum, SSOType } from "@@/CommonTypes.ts";
import BaseAuthMessageDialog from "@/components/reusables/BaseAuthMessageDialog.vue";

const auth0 = useAuth0();
const router = useRouter();

const authMessageTitle = ref("");
const state = ref<SSOType>({
  intended: "",
  source: IntendedSourceEnum.platform13,
  email: "",
});
const redirectAfter_sec = ref(5);

onMounted(async () => {
  if (router.currentRoute.value.name == "Login") {
    authMessageTitle.value = "Logging in";
    login(true);

    return;
  }

  if (router.currentRoute.value.name == "Logout") {
    authMessageTitle.value = "Logging out";

    if (router.currentRoute.value.query?.state) {
      state.value = jwtDecode(router.currentRoute.value.query.state.toString());

      localStorage.setItem("intended", JSON.stringify(`${state.value.intended}`));
      localStorage.setItem("intended_source", JSON.stringify(`${state.value.source}`));
    }

    await logout();

    return;
  }

  // If the user is redirected from Auth0, wait for the id token to be set and then redirect to the intended route
  if (router.currentRoute.value.name === "Auth0Redirect") {
    console.log("router.currentRoute.value.query.code", router.currentRoute.value.query.code);
    authMessageTitle.value = "Please wait, redirecting";

    if (!router.currentRoute.value?.query?.code) {
      window.location.href = getIntended();
      localStorage.removeItem("intended");
      localStorage.removeItem("intended_source");
    }
  }
});

watch(
  () => auth0.idTokenClaims.value,
  (newVal) => {
    if (!newVal?.__raw) {
      return;
    }

    afterAction();
  },
);
</script>