import { ref } from "vue";

import { generalObjectType } from "@@/CommonTypes.ts";
import {
  AgentPortDataOptionsRequestType,
  ExpenseGroupLineOptionsRequestType,
  ExpenseGroupLineOptionType,
  FDAPortCallDataType,
  HeaderTitlesType,
  PortDataOptionsRequestType,
  PortOptionResponseType,
  StatusSub,
  SupplementaryDataType,
} from "@@/PortCallsTypes.ts";

export const defaultSupplementaryData: SupplementaryDataType = {
  id: NaN,
  da_id: "",
  port: {
    id: NaN,
    name: "",
    ext_id: "",
  },
  agent_port_data_options: [],
};
export const defaultFDAPortCallData: FDAPortCallDataType = {
  id: NaN,
  da_id: "",
  est_departure_date: "",
  local_currency: "",
  customer: {
    id: NaN,
    customer_name: "",
  },
  port: { ...defaultSupplementaryData.port },
  vessel: {
    id: NaN,
    vessel_name: "",
    ext_id: "",
    vessel_imo: "",
  },
};

export const defaultPortOptions: PortOptionResponseType = {
  id: NaN,
  name: "",
  ext_id: "",
  recipient_email: "",
  transportation_opt: [],
  accommodation_opt: [],
  launch_hire_opt: [],
};

export default class PortCallAdditionalDataController {
  private static _status = ref<StatusSub>(StatusSub.upcoming); // PDA_SUBMITTED, FDA_SUBMITTED
  static get status(): StatusSub {
    return this._status.value;
  }

  static set status(value: StatusSub) {
    this._status.value = value;
  }

  private static _headerTitle = ref<Record<string, string>>({});
  static get headerTitle(): Record<string, string> {
    return this._headerTitle.value;
  }

  static set headerTitle(value: Record<string, string>) {
    this._headerTitle.value = value;
  }

  private static _rows = ref<
    { [p: string]: PortDataOptionsRequestType[] } | ExpenseGroupLineOptionsRequestType
  >();
  static get rows():
    | { [p: string]: PortDataOptionsRequestType[] }
    | ExpenseGroupLineOptionsRequestType
    | undefined {
    return this._rows.value;
  }

  static set rows(
    value:
      | { [p: string]: PortDataOptionsRequestType[] }
      | ExpenseGroupLineOptionsRequestType
      | undefined,
  ) {
    this._rows.value = value;
  }

  private static _agentPortDataOptionsRequest = ref<AgentPortDataOptionsRequestType[]>();
  static get agentPortDataOptionsRequest(): AgentPortDataOptionsRequestType[] | undefined {
    return this._agentPortDataOptionsRequest.value;
  }

  static set agentPortDataOptionsRequest(value: AgentPortDataOptionsRequestType[] | undefined) {
    this._agentPortDataOptionsRequest.value = value;
  }

  private static _supplementaryData = ref<SupplementaryDataType>({ ...defaultSupplementaryData });

  static get supplementaryData(): SupplementaryDataType {
    return this._supplementaryData.value;
  }

  static set supplementaryData(value: SupplementaryDataType) {
    this._supplementaryData.value = value;
  }

  private static _draftOptions = ref<ExpenseGroupLineOptionsRequestType>([]);

  static get draftOptions(): ExpenseGroupLineOptionsRequestType {
    return this._draftOptions.value;
  }

  static set draftOptions(value: ExpenseGroupLineOptionsRequestType) {
    this._draftOptions.value = value;
  }

  private static _expenseGroupLineOptions = ref<Array<ExpenseGroupLineOptionType>>([]);

  static get expenseGroupLineOptions(): Array<ExpenseGroupLineOptionType> {
    return this._expenseGroupLineOptions.value;
  }

  static set expenseGroupLineOptions(value: Array<ExpenseGroupLineOptionType>) {
    this._expenseGroupLineOptions.value = value;
  }

  private static _fDAPortCallData = ref<FDAPortCallDataType>({ ...defaultFDAPortCallData });

  static get fDAPortCallData(): FDAPortCallDataType {
    return this._fDAPortCallData.value;
  }

  static set fDAPortCallData(value: FDAPortCallDataType) {
    this._fDAPortCallData.value = value;
  }

  private static _portOptions = ref<PortOptionResponseType>({ ...defaultPortOptions });

  static get portOptions(): PortOptionResponseType {
    return this._portOptions.value;
  }

  static set portOptions(value: PortOptionResponseType) {
    this._portOptions.value = value;
  }

  private static _editMode = ref<boolean>(false);

  static get editMode(): boolean {
    return this._editMode.value;
  }

  static set editMode(value: boolean) {
    this._editMode.value = value;
  }

  private static _isValid = ref<boolean>(false);

  static get isValid(): boolean {
    return this._isValid.value;
  }

  static set isValid(value: boolean) {
    this._isValid.value = value;
  }

  private static _errors = ref<generalObjectType>();

  static get errors(): generalObjectType | undefined {
    return this._errors.value;
  }

  static set errors(value: generalObjectType | undefined) {
    this._errors.value = value;
  }

  private static _hasOldData = ref(false);

  static get hasOldData(): boolean {
    return this._hasOldData.value;
  }

  static set hasOldData(value: boolean) {
    this._hasOldData.value = value;
  }

  private static _loading = ref(false);

  static get loading(): boolean {
    return this._loading.value;
  }

  static set loading(value: boolean) {
    this._loading.value = value;
  }

  private static _step = ref(1);

  static get step(): number {
    return this._step.value;
  }

  static set step(value: number) {
    this._step.value = value;
  }

  private static _da_id = ref("");

  static get da_id(): string {
    return this._da_id.value;
  }

  static set da_id(value: string) {
    this._da_id.value = value;
  }

  private static _port = ref<number | null>(null);

  static get port(): number | null {
    return this._port.value;
  }

  static set port(value: number | null) {
    this._port.value = value;
  }

  private static _headerTitleStep1 = ref("Add Disbursement Account details");
  static get headerTitleStep1(): string {
    return this._headerTitleStep1.value;
  }

  static get getHeaderTitles(): HeaderTitlesType {
    return {
      PDA_SUBMITTED: {
        "step-1": this.headerTitleStep1,
        "step-2": "Add service details for cost calculation",
      },
      FDA_SUBMITTED: {
        "step-1": this.headerTitleStep1,
        "step-2": "Add service and invoice details",
      },
    };
  }

  static reset() {
    this._editMode.value = false;
    this._isValid.value = false;
    this._errors.value = undefined;
    this._loading.value = false;
    this._step.value = 1;
    this._da_id.value = "";
    this._port.value = null;
    this._supplementaryData.value = { ...defaultSupplementaryData };
    this._portOptions.value = { ...defaultPortOptions };
    this._status.value = StatusSub.upcoming;
    this._headerTitle.value = {};
  }
}
