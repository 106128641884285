import type { RouteRecordRaw } from "vue-router";
import DashboardLayout from "@/layouts/DashboardLayout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/statistics",
    component: DashboardLayout,
    children: [
      {
        path: "/statistics",
        component: () => import("@/components/pages/statistics/StatisticsConfig.vue"),
        children: [
          {
            path: "/statistics",
            name: "Statistics",
            component: () => import("@/views/statistics/StatisticsView.vue"),
            meta: {
              authRequired: true,
              title: "Statistics",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
