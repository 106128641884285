<template>
  <Dropdown
    ref="dropdownRef"
    v-model="customerID"
    :loading="loading"
    :options="customerGroups"
    class="w-full transition-all t.3s"
    data-filter=""
    option-group-children="items"
    option-group-label="label"
    option-label="customer_name"
    option-value="id"
    placeholder="Select Customer"
    @change="handleChange"
    @show="handleShow"
  >
    <template #dropdownicon>
      <DropdownCaretIcon />
    </template>
    <template #header>
      <div class="p-dropdown-header" data-pc-section="header">
        <pre class="hidden">
          customerPageSize: {{ DashboardStatisticsController.customerPageSize }}
          customerPage: {{ DashboardStatisticsController.customerPage }}
          next: {{ next?.split("?")[1] }}
          customers count: {{ dashboardStore.customers_response.count }}
          customers.length: {{ customers[1]?.items?.length ?? customers[0]?.items?.length }}
        </pre>
        <div class="p-dropdown-filter-container relative" data-pc-section="filtercontainer">
          <span class="p-input-icon-left w-full">
            <i class="pi pi-search"></i>
            <InputText
              ref="dropdownFilterInputRef"
              v-model="dropdownFilterInputValue"
              autocomplete="off"
              class="custom-filter w-full pr-5"
              placeholder="Search"
              role="searchbox"
              @keyup="handleCustomFilter"
            />
          </span>
          <span
            :class="{ 'p-link': dropdownFilterInputValue.length && !loading }"
            class="px-2 border-circle absolute flex right-0"
            style="top: 50%; transform: translate(0, -50%)"
            @click="resetDropdownFilterInput"
          >
            <i v-if="loading && dropdownFilterInputValue.length" class="pi pi-spin pi-spinner"></i>
            <i
              v-else
              :class="{ 'text-gray-400': !dropdownFilterInputValue.length || loading }"
              :title="dropdownFilterInputValue.length ? 'Clear search' : ''"
              class="pi pi-delete-left"
            ></i>
          </span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="p-dropdown-footer py-3">
        <small class="p-dropdown-filter-container flex gap-3">
          <Button class="p-0" text title="Reset filter" @click="resetFilter">
            <i class="pi pi-refresh"></i>
          </Button>
          <span>|</span>
          <span class="flex gap-2 ml-auto">
            <span class="my-auto ml-auto">Rows per page:</span>
            <select
              v-model="DashboardStatisticsController.customerPageSize"
              class="p-dropdown p-component p-inputwrapper p-inputwrapper-filled p-0 border-none text-sm"
              @change="handlePageSizeChange"
            >
              <option
                v-for="option in DashboardStatisticsController.rowsPerPageOptions"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </span>
          <span class="my-auto">
            <span>
              {{ dashboardStore.customers_response.count ? 1 : 0 }} -
              {{ dashboardStore.customers_response.count ? customerCount : 0 }} of
              {{ dashboardStore.customers_response.count }}
            </span>
          </span>
        </small>
      </div>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import Dropdown, { DropdownChangeEvent } from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Button from "primevue/button";

import DropdownCaretIcon from "@/components/icons/DropdownCaretIcon.vue";

import { useDashboardStore } from "@/stores";
import { debounce, getIfScrollBottom } from "@/utilities.ts";
import { CustomerType, generalObjectType } from "@@/CommonTypes.ts";
import { CustomersResponseType } from "@@/PortCallsTypes.ts";
import DashboardStatisticsController from "@/controllers/dashboard/DashboardStatisticsController.ts";

const dashboardStore = useDashboardStore();
const router = useRouter();

const props = defineProps({
  waitToClickContinue: {
    type: Boolean,
    default: true,
  },
});

type CustomerGroupItem = {
  label: string;
  code: string;
  items: Array<CustomerType>;
};

const loading = ref<boolean>(false);
const canLocalFilter = ref<boolean>(false);
const bottomDistance = ref(20); // all for new data before scroll end (px)
const customerID = ref<number | null>();
const selectedCustomer = ref<CustomerType>();
const dropdownRef = ref();
const dropdownListRef = ref();
const dropdownListWrapper = ref();
const dropdownFilterInputRef = ref();
const dropdownFilterInputValue = ref("");
const customers = ref<Array<CustomerGroupItem>>([]);
const next = ref<string | null>("");
const previous = ref<string | null>("");

/**
 * - Write Doc
 */
const customerCount = computed(() =>
  customers.value.reduce(
    (previousValue, currentValue) => previousValue + (currentValue.items?.length ?? 0),
    0,
  ),
);

/**
 * - Write Doc
 */
const customerGroups = computed(() => customers.value.filter((customer) => customer.items.length));

/**
 * - Write Doc
 */
const params = computed(() => ({
  showLoader: false,
  search: dropdownFilterInputValue.value,
}));

onMounted(() => {
  selectedCustomer.value = getLocalSelectedCustomer();
  DashboardStatisticsController.customerPage =
    DashboardStatisticsController.customerPage > DashboardStatisticsController.defaultPage
      ? DashboardStatisticsController.customerPage
      : +(
          router.currentRoute.value.query.customer_page ?? DashboardStatisticsController.defaultPage
        );
  DashboardStatisticsController.customerPageSize =
    DashboardStatisticsController.customerPageSize !== DashboardStatisticsController.defaultPageSize
      ? DashboardStatisticsController.customerPageSize
      : +(
          router.currentRoute.value.query.customer_page_size ??
          DashboardStatisticsController.defaultPageSize
        );
});

const handlePageSizeChange = async () => {
  next.value = "";
  dropdownListWrapper.value.scrollTop = 0;
  DashboardStatisticsController.customerPage = 1;

  await fetchCustomers({
    ...params.value,
    page: DashboardStatisticsController.customerPage,
    page_size: DashboardStatisticsController.customerPageSize,
  });
};

const getLocalSelectedCustomer = () => {
  const localSelectedCustomer = localStorage.getItem("selectedCustomer");
  return localSelectedCustomer ? JSON.parse(localSelectedCustomer) : undefined;
};

/**
 * @param {CustomerType[]} _customers - Customers
 */
const setCustomers = (_customers?: Array<CustomerType>) => {
  if (!_customers?.length) {
    _customers = dashboardStore.customers;
  }

  if (canLocalFilter.value && dropdownFilterInputValue.value) {
    _customers = _customers.filter(
      (customer: CustomerType) =>
        customer.customer_name
          .toLowerCase()
          .includes(dropdownFilterInputValue.value.toLowerCase()) ||
        customer.id.toString().toLowerCase().includes(dropdownFilterInputValue.value.toLowerCase()),
    );
  }

  if (!dashboardStore.customer_id || !selectedCustomer.value) {
    customers.value = [
      {
        label: "Select from",
        code: "selectFrom",
        items: _customers,
      },
    ];

    dashboardStore.show_select_customer_dialog = true;
    dashboardStore.customer_id = null;
    return;
  }

  _customers = _customers.filter(
    (customer: CustomerType) => customer.id !== dashboardStore.customer_id,
  );

  customers.value = [
    {
      label: "Selected",
      code: "selected",
      items: [selectedCustomer.value],
    },
    {
      label: "Select from",
      code: "selectFrom",
      items: _customers,
    },
  ];
};

/**
 * @param {DropdownChangeEvent} event - The event object.
 */
const handleChange = (event: DropdownChangeEvent) => {
  if (props.waitToClickContinue) {
    return;
  }

  processCustomerID(event.value);
};

/**
 * - Write Doc
 */
const handleShow = () => {
  const dropdownRefElement = dropdownRef.value.$el;
  if (!dropdownRefElement?.id) {
    return;
  }

  dropdownListRef.value = dropdownRefElement
    .closest("body")
    .querySelector(`#${dropdownRefElement.id}_list`);
  if (!dropdownListRef.value) {
    return;
  }

  dropdownFilterInputRef.value.value = dropdownRefElement.dataset.filter;
  dropdownFilterInputRef.value?.$el?.focus();

  dropdownListWrapper.value = dropdownListRef.value.closest(".p-dropdown-items-wrapper");
  if (!dropdownListWrapper.value) {
    return;
  }
  dropdownListWrapper.value.scrollTop = 0;
  dropdownListWrapper.value.addEventListener("scroll", handleOnScroll);
};

/**
 * - Write Doc
 */
const handleInputValueChangeWithDebounce = () => {
  next.value = "";
  dropdownRef.value.$el.dataset.filter = dropdownFilterInputValue.value;

  if (props.waitToClickContinue) {
    selectedCustomer.value = undefined;
    customerID.value = undefined;
  }

  canLocalFilter.value = true;
  setCustomers(); // it'll help to search/filter locally

  DashboardStatisticsController.customerPage = 1;
  debounce(
    async () => {
      dropdownListWrapper.value.scrollTop = 0;

      await fetchCustomers({
        ...params.value,
        page: DashboardStatisticsController.customerPage,
        page_size: DashboardStatisticsController.customerPageSize,
      });

      canLocalFilter.value = false;
    },
    { search: dropdownFilterInputValue.value },
  );
};

/**
 * @param {HTMLInputElement} event - The event object.
 */
const handleCustomFilter = (event) => {
  event.preventDefault();
  if (!dropdownFilterInputValue.value) {
    return;
  }
};

/**
 * - Write Doc
 */
const resetDropdownFilterInput = () => {
  if (!dropdownFilterInputValue.value) {
    return;
  }

  dropdownFilterInputValue.value = "";
};

/**
 * @param {Event} event - Scroll event
 */
const handleOnScroll = async (event: Event) => {
  if (
    loading.value ||
    next.value === null ||
    DashboardStatisticsController.customerPage * DashboardStatisticsController.customerPageSize >=
      dashboardStore.customers_response.count
  ) {
    return;
  }

  next.value = "";
  previous.value = "";
  if (getIfScrollBottom(event.target as HTMLElement) <= bottomDistance.value - 1) {
    const { data } = (await fetchCustomers({
      ...params.value,
      page: DashboardStatisticsController.customerPage + 1,
      page_size: DashboardStatisticsController.customerPageSize,
    })) as { data: CustomersResponseType };

    next.value = data.next;
    previous.value = data.previous;
  }

  if (next.value) {
    const newURL = new URL(next.value);
    const page = newURL.searchParams.get("page");
    DashboardStatisticsController.customerPage = page
      ? +page - 1
      : DashboardStatisticsController.customerPage;

    return;
  }

  if (previous.value) {
    const newURL = new URL(previous.value);
    const page = newURL.searchParams.get("page");
    DashboardStatisticsController.customerPage = page
      ? +page + 1
      : DashboardStatisticsController.customerPage;
  }
};

/**
 * Fetching customers
 * @typedef {import('@@/CommonTypes.ts').ResponseDataType} ResponseDataType
 * @param {generalObjectType} params - API params
 * @returns {Promise<ResponseDataType<CustomersResponseType>>} - Returns promise
 */
const fetchCustomers = async (params: generalObjectType) => {
  loading.value = true;
  const response = await dashboardStore.fetchCustomersAction<CustomersResponseType>(params);
  loading.value = false;
  return response;
};

/**
 * @description It'll help to populate _UpcomingPortDataTable_ by updating customer_id on route
 * @param {number | null} customerID - The customer id
 */
const processCustomerID = async (customerID: number | null) => {
  let query = {};
  if (customerID) {
    setSelectedCustomer(dashboardStore.customers, customerID);
    localStorage.setItem("customer_id", JSON.stringify(customerID));
    localStorage.setItem("selectedCustomer", JSON.stringify(selectedCustomer.value));
    dashboardStore.customer_id = customerID;
    query = {
      customer_id: customerID,
    };
  } else {
    localStorage.removeItem("customer_id");
    localStorage.removeItem("selectedCustomer");
    dashboardStore.customer_id = null;
  }

  dashboardStore.show_select_customer_dialog = !customerID;
  await Promise.all([
    dashboardStore.fetchUpcomingPortCallsAction(),
    dashboardStore.fetchCompletedPortCallsAction(),
  ]);

  console.log("router", router, query);
};

const setSelectedCustomer = (
  _customers: Array<CustomerType>,
  newCustomerID: CustomerType["id"],
) => {
  selectedCustomer.value = newCustomerID
    ? _customers.find((customer) => customer.id === newCustomerID) ??
      (getLocalSelectedCustomer() || selectedCustomer.value)
    : selectedCustomer.value;

  if (selectedCustomer.value) {
    localStorage.setItem("selectedCustomer", JSON.stringify(selectedCustomer.value));
  }

  if (!newCustomerID) {
    localStorage.removeItem("selectedCustomer");
  }
};

const resetFilter = () => {
  DashboardStatisticsController.customerPage = 1;
  DashboardStatisticsController.customerPageSize = DashboardStatisticsController.defaultPageSize;

  if (!dropdownFilterInputValue.value) {
    handlePageSizeChange();
    return;
  }

  dropdownFilterInputValue.value = "";
};

watch(
  () => dashboardStore.customer_id,
  (newCustomerID) => {
    const customer_id = localStorage.getItem("customer_id");
    customerID.value = newCustomerID ?? (customer_id ? JSON.parse(customer_id) : null);

    setSelectedCustomer(dashboardStore.customers, customerID.value ?? 0);
    setCustomers();
  },
  {
    immediate: true,
  },
);

watch(
  () => dashboardStore.customers,
  (newCustomers) => {
    if (!selectedCustomer.value?.id) {
      setSelectedCustomer(newCustomers, customerID.value ?? 0);
    }

    setCustomers(newCustomers);
  },
  {
    immediate: true,
  },
);

watch(
  () => dropdownFilterInputValue.value,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      handleInputValueChangeWithDebounce();
    }
  },
);

defineExpose({
  customerID,
  processCustomerID,
});
</script>
