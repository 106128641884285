import { defaultCurrency } from "@/stores/port-call/port-call.state.ts";

import {
  AdditionalCost,
  AmountCustomerCurrencyEnum,
  EmissionReductionPotentialsType,
  EmissionReductionPotentialType,
  ExpenseGroupLine,
  GenericInPortOptionEnum,
  PdaFdaTotalType,
  ReducedEmissionsAndAbatementCostType,
  Status,
  StatusSub,
  SupplierCompaniesType,
  SupplierCompanyType,
  SuppliersType,
  TotalCostAndChangeEmissionsType,
} from "@@/PortCallsTypes.ts";
import { PortCallGetters, PortCallStoreContext } from "@@/stores/modules/PortCallTypes.ts";

const getters: PortCallGetters = {
  getPortCall(this: PortCallStoreContext) {
    return this.$state.portCall;
  },
  getCustomerCurrencyCode(this: PortCallStoreContext) {
    return this.$state.dataSeed
      ? defaultCurrency
      : this.$state.portCall.customer_currency_code || defaultCurrency;
  },
  getSuppliers(this: PortCallStoreContext) {
    return this.$state.portCall.suppliers;
  },
  getSuppliersName(this: PortCallStoreContext) {
    const suppliers = this.$state.portCall.suppliers ?? {};
    return Object.keys(suppliers);
  },
  getAdditionalCostsWithTotal(this: PortCallStoreContext) {
    const total: ExpenseGroupLine = {
      id: 0,
      other_service_description: "Total",
      proforma_amount_customer_currency: 0,
      total_amount_customer_currency: 0,
    };

    const additionalCosts = JSON.parse(JSON.stringify(this.$state.portCall.additional_costs ?? []));

    const targetAmountCustomerCurrency =
      AmountCustomerCurrencyEnum[this.$state.portCall.status_sub];

    return additionalCosts?.map((additionalCost: AdditionalCost) => {
      if (additionalCost.expense_group_lines?.length > 1) {
        total.other_service_description = `Total ${additionalCost.expense_group_name}`;
        total[targetAmountCustomerCurrency] =
          additionalCost.expense_group_lines?.reduce((prev: number, current: ExpenseGroupLine) => {
            return prev + (current[targetAmountCustomerCurrency] ?? 0);
          }, 0) ?? 0;
        additionalCost.expense_group_lines = [...additionalCost.expense_group_lines, { ...total }];
      }

      return additionalCost;
    });
  },
  getAdditionalCosts(this: PortCallStoreContext) {
    return this.$state.portCall.additional_costs ?? [];
  },
  hasAnyCompanyInAnySupplier(this: PortCallStoreContext): boolean {
    const suppliers = this.$state.portCall.suppliers ?? {};
    return Object.entries(suppliers).some(([, value]) => value.length > 0);
  },
  getSupplierSelectedCompany(this: PortCallStoreContext) {
    return (supplier: string): SupplierCompanyType => {
      const companies = this.$state.portCall.suppliers?.[supplier];

      return (
        companies?.find((company: SupplierCompanyType) => company.selected) ?? {
          ...(companies?.[0] ?? ({} as SupplierCompanyType)),
          selected: true,
        }
      );
    };
  },
  getSupplierSelectedCompanies(this: PortCallStoreContext) {
    return (supplier: string): Array<SupplierCompanyType> => {
      const companies = this.$state.portCall.suppliers?.[supplier];

      /**
       * This is a workaround for the following issue:
       * - Returns only the selected companies
       * - Duplicate companies are not allowed, just sum of the emission and cost of the duplicate companies
       */
      const selectCompanies: SupplierCompaniesType = [];
      for (const company of companies) {
        if (!company.selected) {
          continue;
        }

        const selectCompaniesIndex = selectCompanies.findIndex((c) => c.id === company.id);

        if (selectCompaniesIndex === -1) {
          selectCompanies.push(company);
        } else {
          selectCompanies[selectCompaniesIndex] = {
            ...company,
            emission: selectCompanies[selectCompaniesIndex].emission + company.emission,
            cost: selectCompanies[selectCompaniesIndex].cost + company.cost,
          };
        }
      }

      return (
        selectCompanies ?? [
          {
            ...(companies?.[0] ?? ({} as SupplierCompanyType)),
            selected: true,
          },
        ]
      );
    };
  },
  getSelectedCompanies(this: PortCallStoreContext): SuppliersType {
    return Object.fromEntries(
      Object.entries(this.$state.portCall.suppliers).map(([key, value]) => [
        key,
        value.filter((company: SupplierCompanyType) => company.selected),
      ]),
    );
  },
  getSelectedCompaniesFlat(this: PortCallStoreContext): SupplierCompaniesType {
    return Object.values(this.getSelectedCompanies).flat();
  },
  getSupplierCompanies(this: PortCallStoreContext) {
    return (supplier: string): SupplierCompaniesType => {
      const companies = this.$state.portCall.suppliers?.[supplier];
      return companies ?? [];
    };
  },
  // Emissions
  getSupplierMinCarbonEmissionCompany(this: PortCallStoreContext) {
    return (supplier: string): SupplierCompanyType => {
      const companies = this.$state.portCall.suppliers?.[supplier];

      return (
        companies?.reduce((prev, current) => {
          return prev?.emission < current?.emission ? prev : current;
        }, {} as SupplierCompanyType) ?? ({} as SupplierCompanyType)
      );
    };
  },
  getMinCarbonEmissionCompany(this: PortCallStoreContext) {
    let maxCarbonEmissionCompany = {};
    maxCarbonEmissionCompany = Object.fromEntries(
      this.getSuppliersName.map((supplierType) => [
        supplierType,
        this.getSupplierMinCarbonEmissionCompany(supplierType),
      ]),
    );

    return maxCarbonEmissionCompany;
  },
  getSupplierMaxCarbonEmissionCompany(this: PortCallStoreContext) {
    return (supplier: string): SupplierCompanyType => {
      const companies = this.$state.portCall.suppliers?.[supplier];

      return (
        companies?.reduce((prev, current) => {
          return prev?.emission > current?.emission ? prev : current;
        }, {} as SupplierCompanyType) ?? ({} as SupplierCompanyType)
      );
    };
  },
  getMaxCarbonEmissionCompany(this: PortCallStoreContext) {
    let maxCarbonEmissionCompany: Record<string, SupplierCompanyType> = {};
    maxCarbonEmissionCompany = Object.fromEntries(
      this.getSuppliersName.map((supplierType) => [
        supplierType,
        this.getSupplierMaxCarbonEmissionCompany(supplierType),
      ]),
    );

    return maxCarbonEmissionCompany;
  },
  getSupplierPotentialSaving(this: PortCallStoreContext) {
    return (supplier: string) =>
      (this.getMaxCarbonEmissionCompany[supplier]?.emission || 0) -
      (this.getMinCarbonEmissionCompany[supplier]?.emission || 0);
  },
  getPotentialSaving(this: PortCallStoreContext) {
    const totalPotentialSaving: Record<string, number> = {};
    Object.entries(this.$state.defaultSuppliersCompany).forEach(([supplier, value]) => {
      totalPotentialSaving[supplier] = 0;

      if (this.$state.portCall.status_sub === StatusSub.PDA_SUBMITTED) {
        const _value = value as SupplierCompanyType;
        totalPotentialSaving[supplier] =
          (_value?.emission || 0) - (this.getMinCarbonEmissionCompany[supplier]?.emission || 0);
      }
    });

    return totalPotentialSaving;
  },
  getTotalPotentialSaving(this: PortCallStoreContext) {
    return (
      Object.values(this.getPotentialSaving).reduce((prev, current) => {
        return prev + current;
      }, 0) ?? 0
    );
  },
  getSupplierActualSaving(this: PortCallStoreContext) {
    return (supplier: string) =>
      (this.getMaxCarbonEmissionCompany[supplier]?.emission || 0) -
      (this.getSupplierSelectedCompany(supplier)?.emission || 0);
  },
  getActualSaving(this: PortCallStoreContext) {
    const totalActualSaving: Record<string, number> = {};
    Object.entries(this.$state.defaultSuppliersCompany).forEach(([supplierType, value]) => {
      totalActualSaving[supplierType] = 0;

      if (this.$state.portCall.status_sub === StatusSub.PDA_SUBMITTED) {
        const _value = value as SupplierCompanyType;
        totalActualSaving[supplierType] =
          (_value?.emission || 0) - (this.getSupplierSelectedCompany(supplierType)?.emission || 0);
      }
    });

    return totalActualSaving;
  },
  getTotalActualSaving(this: PortCallStoreContext) {
    return (
      Object.values(this.getActualSaving).reduce((prev, current) => {
        return prev + current;
      }, 0) ?? 0
    );
  },
  // Costs
  getSupplierCheapestCompany(this: PortCallStoreContext) {
    return (supplier: string): SupplierCompanyType => {
      const companies = this.$state.portCall.suppliers?.[supplier];
      return (
        companies?.reduce((prev, current) => {
          return prev?.cost < current?.cost ? prev : current;
        }, {} as SupplierCompanyType) ?? ({} as SupplierCompanyType)
      );
    };
  },
  getCheapestCompanies(this: PortCallStoreContext) {
    const cheapestCompanies: Record<string, SupplierCompanyType> = {};

    Object.keys(this.$state.portCall?.suppliers ?? {}).forEach(
      (supplier) => (cheapestCompanies[supplier] = this.getSupplierCheapestCompany(supplier)),
    );

    return cheapestCompanies;
  },
  getAdditionalTotalCostsFlat(this: PortCallStoreContext): ExpenseGroupLine[] {
    return this.getAdditionalCosts
      .map((additionalCost: AdditionalCost) => {
        return additionalCost.expense_group_lines;
      })
      .flat();
  },
  // Others
  getTotalCostAndChangeEmissions(this: PortCallStoreContext) {
    const totalCostAndChangeEmissions: TotalCostAndChangeEmissionsType = {
      totalCost: {
        pda: 0,
        fda: this.portCall.total_amount_customer_currency ?? 0,
      },
      crewChangeEmissions: {
        pda: 0,
        fda: this.portCall.emission_potential ?? 0,
      },
    };

    this.getSelectedCompaniesFlat.forEach((company: SupplierCompanyType) => {
      totalCostAndChangeEmissions.totalCost.pda += company.cost;
      totalCostAndChangeEmissions.crewChangeEmissions.pda += company.emission;
    });

    this.getAdditionalTotalCostsFlat.forEach((cost: ExpenseGroupLine) => {
      totalCostAndChangeEmissions.totalCost.pda += cost.proforma_amount_customer_currency ?? 0;
    });

    return totalCostAndChangeEmissions;
  },
  getReducedEmissionsAndAbatementCost(this: PortCallStoreContext) {
    const isProforma = this.portCall.status === Status.PROFORMA;
    const reduced_emissions = this.portCall.reduced_emissions || 0;
    const abatement_cost = this.portCall.abatement_cost || 0;

    const reducedEmissionsAndAbatementCost: ReducedEmissionsAndAbatementCostType = {
      reducedEmissions: {
        pda: isProforma ? 0 : reduced_emissions,
        fda: reduced_emissions,
      },
      abatementCost: {
        pda: isProforma ? 0 : abatement_cost,
        fda: abatement_cost,
      },
    };

    if (!isProforma) {
      return reducedEmissionsAndAbatementCost;
    }

    const _reducedEmissions: PdaFdaTotalType = {
      pda: 0,
      fda: this.portCall.reduced_emissions || 0,
    };
    const _abatementCost: PdaFdaTotalType = {
      pda: 0,
      fda: this.portCall.abatement_cost || 0,
    };
    const _emissionReductionPotentials: EmissionReductionPotentialsType = {};

    /**
     * Ref: https://wilhelmsenplatform13.slack.com/archives/C05GFUD4B1U/p1698685349011539
     * Story: https://dev.azure.com/gture/Wilhelmsen%20Platform%2013/_workitems/edit/10568
     *
     * // If TCES < 0, set Abatement Cost = 0
     * Total cost of emission savings (TCES) =
     * {
     *  cost of custom (AC) - cost of default (AC) +
     *  cost of custom (LH) - cost of default (LH) +
     *  cost custom (LT) - cost of default (LT)
     * }
     *
     * // If reduced emissions(emission savings) < 0, set reduced emission(emission savings) = 0
     * Total emission savings (TES) =
     * {
     *  (emissions of default (AC) - emissions custom (AC)) +
     *  (emissions default (LH) - emissions custom (LH)) +
     *  (emissions default (LT) - emissions custom (LT))
     * }
     *
     * TCES_TES = TCES / TES
     *
     * Abatement Cost (UI) = Max(0, TCES_TES)
     *
     * Note: What is default and custom?
     * - Default: The company that has the lowest cost (send by the backend)
     * - Custom: The custom selected company (selected by the user)
     */
    Object.entries(this.getSelectedCompanies).forEach(([supplier, value]) => {
      const _defaultCompanies = this.$state.defaultSuppliersCompany?.[supplier];
      const _defaultCompany: SupplierCompanyType =
        this.$state.portCall.status_sub === StatusSub.PDA_SUBMITTED
          ? _defaultCompanies
          : _defaultCompanies?.[0];
      const _selectedCompany = value?.[0];

      const _emissions = (_defaultCompany?.emission || 0) - (_selectedCompany?.emission || 0);
      _reducedEmissions.pda += _emissions;

      const _costs = (_selectedCompany?.cost || 0) - (_defaultCompany?.cost || 0);
      _abatementCost.pda += _costs;

      _emissionReductionPotentials[supplier] = {
        abatement_cost: +(_costs / _emissions || 0).toFixed(2),
        reduced_emissions: +_emissions.toFixed(2),
        potential_reduced_emissions_percentage: +Math.max(
          0,
          this.getTotalPotentialSaving
            ? (this.getSupplierActualSaving(supplier) / this.getTotalPotentialSaving) * 100
            : 0,
        ).toFixed(2),
        emission_reduction_potential: this.getSupplierPotentialSaving2(supplier),
      } as EmissionReductionPotentialType;
    });

    reducedEmissionsAndAbatementCost.reducedEmissions.pda = Math.max(0, _reducedEmissions.pda);
    reducedEmissionsAndAbatementCost.abatementCost.pda = Math.max(
      0,
      _abatementCost.pda / _reducedEmissions.pda || 0,
    );

    this.setEmissionReductionPotentials(_emissionReductionPotentials);

    return reducedEmissionsAndAbatementCost;
  },
  getPotentialReducedEmissionsPercentage(this: PortCallStoreContext) {
    const isProforma = this.portCall.status === Status.PROFORMA;
    const potential_reduced_emissions_percentage =
      this.portCall.potential_reduced_emissions_percentage || 0;

    /**
     * Ref: https://wilhelmsenplatform13.slack.com/archives/C05GFUD4B1U/p1698843885429789
     * Story: https://dev.azure.com/gture/Wilhelmsen%20Platform%2013/_workitems/edit/10746
     */
    const potentialReducedEmissionsPercentage: PdaFdaTotalType = {
      pda: isProforma ? 0 : potential_reduced_emissions_percentage,
      fda: potential_reduced_emissions_percentage,
    };

    if (!isProforma) {
      return potentialReducedEmissionsPercentage;
    }

    const totalPotentialSaving = this.getTotalPotentialSaving;

    potentialReducedEmissionsPercentage.pda = Math.max(
      0,
      totalPotentialSaving ? (this.getTotalActualSaving / totalPotentialSaving) * 100 : 0,
    );

    return potentialReducedEmissionsPercentage;
  },
  getEmissionReductionPotentials(this: PortCallStoreContext) {
    return this.$state.emissionReductionPotentials;
  },
  getSupplierPotentialSaving2(this: PortCallStoreContext) {
    return (supplier: string) =>
      (this.$state.defaultSuppliersCompany[supplier]?.emission || 0) -
      (this.getMinCarbonEmissionCompany[supplier]?.emission || 0);
  },
  getTotalPotentialSaving2(this: PortCallStoreContext) {
    const totalPotentialSaving: Partial<GenericInPortOptionEnum<number>> = {};

    Object.entries(this.$state.defaultSuppliersCompany).forEach(([supplier]) => {
      if (this.$state.portCall.status_sub === StatusSub.PDA_SUBMITTED) {
        totalPotentialSaving[supplier] = this.getSupplierPotentialSaving2(supplier);
      } else {
        totalPotentialSaving[supplier] = 0;
      }
    });

    return totalPotentialSaving;
  },
  getTotalEmissionReductionPotential(this: PortCallStoreContext) {
    return (
      Object.values(this.getTotalPotentialSaving2).reduce((prev, current) => {
        return prev + current;
      }, 0) ?? 0
    );
  },
};

export default getters;
