<template>
  <Dialog v-model:visible="localIsOffline" :style="{ width: '30vw' }" modal>
    <template #header>
      <h3 class="flex gap-2 text-orange-500 mb-0">
        <i class="material-icons material-icons-round">wifi_off</i> <span>You are offline</span>
      </h3>
    </template>
    <p>Please check your internet connection.</p>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";
import Dialog from "primevue/dialog";

import { useSiteStateStore } from "@/stores";

const siteStateStore = useSiteStateStore();

const localIsOffline = ref(false);

const isOffline = computed({
  get: () => siteStateStore.isOffline,
  set: (value) => {
    siteStateStore.setIsOffline(value);
  },
});

window.addEventListener("online", () => {
  isOffline.value = false;
});
window.addEventListener("offline", () => {
  isOffline.value = true;
});

watch(
  () => isOffline.value,
  (value) => {
    localIsOffline.value = value;
  },
);
</script>
