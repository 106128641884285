<template>
  <RouterView v-if="isValidStatus" />
  <E404View v-else />
</template>
<script lang="ts" setup>
import { computed, watch } from "vue";
import { useRouter } from "vue-router";

import E404View from "@/views/errors/E404View.vue";

import PortCallAdditionalDataController from "@/controllers/port-call/PortCallAdditionalDataController.ts";

import { StatusSub } from "@@/PortCallsTypes.ts";

const router = useRouter();

const acceptedStatus: StatusSub[] = [StatusSub.PDA_SUBMITTED, StatusSub.FDA_SUBMITTED];

const status = computed(
  () => StatusSub[router?.currentRoute?.value?.params?.status?.toString()], // PDA_SUBMITTED or FDA_SUBMITTED
);
const isValidStatus = computed<boolean>(() => Object.values(acceptedStatus).includes(status.value));
const headerTitles = computed(() => PortCallAdditionalDataController.getHeaderTitles);
const headerTitle = computed<Record<string, string>>(() => {
  return isValidStatus.value ? headerTitles.value?.[status.value] : {};
});
watch(
  () => status.value,
  (_status) => {
    if (acceptedStatus.includes(_status)) {
      PortCallAdditionalDataController.status = StatusSub[_status];
      PortCallAdditionalDataController.headerTitle = headerTitle.value;
    }
  },
  {
    immediate: true,
  },
);
</script>
