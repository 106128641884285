import type { RouteRecordRaw } from "vue-router";
import AuthLayout from "@/layouts/AuthLayout.vue";
import AuthView from "@/views/auth/AuthView.vue";
import ProfileView from "@/views/auth/ProfileView.vue";
import DashboardLayout from "@/layouts/DashboardLayout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: AuthView,
        meta: {
          title: "Login",
          isPublic: true,
        },
      },
      {
        path: "logout",
        name: "Logout",
        component: AuthView,
        meta: {
          title: "Logout",
          isPublic: true,
        },
      },
      {
        path: "auth0-redirect",
        name: "Auth0Redirect",
        component: AuthView,
        meta: {
          title: "Redirecting...",
          isPublic: true,
        },
      },
      {
        path: "/",
        component: DashboardLayout,
        children: [
          {
            path: "profile",
            name: "Profile",
            component: ProfileView,
            meta: {
              title: "Profile",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
