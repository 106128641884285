import { ref } from "vue";

import helper from "@/Helper.ts";

import ThemeLayout from "@/layouts/ThemeLayout.vue";

import type { RouteRecordRaw } from "vue-router";

const { components } = helper.getMenu();

const children = ref<RouteRecordRaw[]>([]);

Object.entries(components.value).forEach(([key, value]) => {
  children.value.push({
    path: key,
    component: value as any,
  });
});

const routes: RouteRecordRaw[] = [
  {
    path: "/theme",
    component: ThemeLayout,
    children: children.value,
    meta: {
      title: "Theme",
      isPublic: true,
    },
  },
];

export default routes;
