import type { RouteRecordRaw } from "vue-router";

import DashboardLayout from "@/layouts/DashboardLayout.vue";
import AdditionalDataOnUpcomingPortCall from "@/views/designs-pre/AdditionalDataOnUpcomingPortCall.vue";
import DesignsPreView from "@/views/designs-pre/DesignsPreView.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/designs-pre",
    component: DashboardLayout,
    meta: {
      title: "Designs Pre",
    },
    children: [
      {
        path: "/designs-pre",
        component: DesignsPreView,
        children: [
          {
            path: "additional-data-on-upcoming-port-call",
            name: "AdditionalDataOnUpcomingPortCall",
            component: AdditionalDataOnUpcomingPortCall,
            meta: {
              title: "Designs Pre - Additional Data On UPC",
            },
          },
        ],
      },
    ],
  },
];

export default routes;
