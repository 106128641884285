import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { auth0 } from "@/services/auth.service.ts";

import LoaderController from "@/controllers/LoaderController.ts";
import { error as errorToast } from "@/services/toast.service.ts";
import { upperFirst } from "tiny-case";

console.debug("Import meta -->", import.meta);

const config = {
  baseURL: `${import.meta.env.VITE_APP_API_SCHEME}${import.meta.env.VITE_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "X-CSRFToken": getCookies("csrftoken"),
    // "X-REQUESTED-WITH": "XMLHttpRequest",
    // Authorization: `Bearer ${auth0.getAccessTokenSilently()}`,
  },
};

const useAxios = (): AxiosInstance => {
  // const siteStateStore = useSiteStateStore();
  // return siteStateStore.apiCallable ? axiosInstance : (Axios as unknown as AxiosInstance);
  return axiosInstance;
};

const axiosInstance = axios.create(config);

const onRequest = async <T extends InternalAxiosRequestConfig>(config: T): Promise<T> => {
  if (config.headers?.showLoader ?? true) {
    LoaderController.showLoader();
  }

  await auth0.getAccessTokenSilently().then((token) => {
    config.headers["Authorization"] = `Bearer ${token}`;
  });
  return config;
};

const onRequestError = () => (error: never) => {
  LoaderController.hideLoader();

  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest, onRequestError);

const onResponseError = <T extends AxiosError>(error: T): Promise<T> => {
  LoaderController.hideLoader();

  console.log("onResponseError ===========>");
  console.error("error: ", error.message);
  const cond =
    typeof error.response?.data === "object" && Object.keys(error.response?.data ?? {}).length > 0;

  if (cond) {
    const errs = Object.entries(error.response?.data ?? {});

    errs.forEach(([key, values]: [string, string | string[]]) => {
      if (typeof values === "string") {
        values = [values];
      }

      if (typeof values === "object") {
        values = values.map((value) => value.toString());

        values?.forEach((value: string) => {
          const body = key !== "error" ? `${upperFirst(key)}: ${value}` : value;
          errorToast("Something went wrong!", body, 5000);
        });
      }
    });
  } else {
    errorToast(
      "Something went wrong!",
      error?.response?.statusText ?? error?.message ?? "Something went wrong!",
      5000,
    );
  }

  return Promise.reject(error);
};

const onResponse = <T extends AxiosResponse>(response: T): T => {
  if (response.config.headers?.showLoader ?? true) {
    LoaderController.hideLoader();
  }

  return response;
};

axiosInstance.interceptors.response.use(onResponse, onResponseError);

export { useAxios };
