import DashboardStatisticsController from "@/controllers/dashboard/DashboardStatisticsController.ts";
import { useAxios } from "@/services/axios.service.ts";

import { HTTP_FILTERS } from "@/api/dashboard/httpEnums.ts";
import { HTTP_DATA } from "@/api/port-call/httpEnums.ts";

import { generalObjectType } from "@@/CommonTypes.ts";

const fetchPorts = async (params: generalObjectType = {}) => {
  const ordering = "name";

  params = {
    ...params,
    ordering,
  };

  return useAxios().get(HTTP_FILTERS.GET_PORTS, { params });
};
const fetchVessels = async (params: generalObjectType = {}) => {
  const ordering = "vessel_name";

  params = {
    ...params,
    ordering,
  };

  return useAxios().get(HTTP_FILTERS.GET_VESSELS, { params });
};
const fetchVesselManagers = async () => useAxios().get(HTTP_FILTERS.GET_VESSEL_MANAGERS);

const fetchUpcomingPortCalls = async () => {
  return useAxios().get(`${HTTP_DATA.GET_UPCOMING_PORT_CALLS}`, {
    params: DashboardStatisticsController.getUpcomingPortCallParams(),
  });
};

const fetchCompletedPortCalls = async () => {
  return useAxios().get(`${HTTP_DATA.GET_COMPLETED_PORT_CALLS}`, {
    params: DashboardStatisticsController.getCompletedPortCallParams(),
  });
};

const fetchCustomers = async (params: generalObjectType) => {
  const headers = {};
  const showLoader = !!(params?.showLoader ?? true);
  delete params?.showLoader;
  if (!showLoader) {
    headers["showLoader"] = false;
  }

  const ordering = "customer_name";

  params = {
    ...params,
    ordering,
  };

  return useAxios().get(HTTP_FILTERS.GET_CUSTOMERS, { params, headers });
};

export {
  fetchPorts,
  fetchUpcomingPortCalls,
  fetchCompletedPortCalls,
  fetchVessels,
  fetchVesselManagers,
  fetchCustomers,
};
