import { computed, onMounted, onUnmounted, ref } from "vue";

export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth);

  const onWidthChange = () => (windowWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener("resize", onWidthChange));

  const bootstrapBreakpoints = computed(() => {
    if (windowWidth.value < 576) {
      return "xs";
    }
    if (windowWidth.value >= 576 && windowWidth.value < 768) {
      return "sm";
    }
    if (windowWidth.value >= 768 && windowWidth.value < 992) {
      return "md";
    }
    if (windowWidth.value >= 992 && windowWidth.value < 1200) {
      return "lg";
    }
    if (windowWidth.value >= 1200) {
      return "xl";
    }
    return null; // This is an unreachable line, simply to keep eslint happy.
  });

  const width = computed(() => windowWidth.value);

  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  return { width, type: bootstrapBreakpoints };
};
