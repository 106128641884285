<template>
  <OfflineWarning />
  <RouterView />
  <Toast style="top: calc(var(--header-height) + 1.25rem); right: 1.25rem" />
</template>

<script lang="ts" setup>
// import libraries
import { watch } from "vue";
import { RouterView, useRouter } from "vue-router";

import Toast from "primevue/toast";

// import vue files
import OfflineWarning from "@/components/common/OfflineWarning.vue";

// import services, controllers, helpers, utilities, etc.
import helper from "@/Helper.ts";

// import states
import { useSiteStateStore } from "@/stores";

// import types

const siteStateStore = useSiteStateStore();
const router = useRouter();

watch(
  () => siteStateStore.titleReplace,
  (titleReplace) => {
    helper.replacePageTitle(router.currentRoute.value, titleReplace);
  },
  {
    deep: true,
  },
);
</script>
