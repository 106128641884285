import { ref } from "vue";

export default class AuthController {
  private static _checkingBackendState = ref(false);

  static get checkingBackendState(): boolean {
    return this._checkingBackendState.value;
  }

  static set checkingBackendState(value: boolean) {
    this._checkingBackendState.value = value;
  }
}
