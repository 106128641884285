<template>
  <div class="theme-sidebar mt-5">
    <PanelMenu :model="items" class="w-full md:w-25rem">
      <template #item="{ label, item, props, hasSubmenu }">
        <router-link v-if="item.route" #default="routerProps" :to="item.route" custom>
          <a v-bind="{ ...props.action, href: routerProps.href }" @click="routerProps.navigate">
            <span v-bind="props.icon" />
            <span v-bind="props.label">{{ label }}</span>
          </a>
        </router-link>
        <a v-else :target="item.target" v-bind="{ ...props.action, href: item.url }">
          <span v-bind="props.icon" />
          <span v-bind="props.label">{{ label }}</span>
          <span :class="[hasSubmenu && 'pi pi-fw pi-angle-down']" v-bind="props.submenuicon" />
        </a>
      </template>
    </PanelMenu>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import PanelMenu from "primevue/panelmenu";
import { useTheme } from "@/composables/useTheme";

import type { MenuItem } from "primevue/menuitem";

const { menuItems } = useTheme();

const items = ref<Array<MenuItem>>([
  {
    label: "Home",
    icon: "pi pi-fw pi-circle-on",
    route: "/",
  },
  ...menuItems.value,
]);
</script>

<style lang="scss">
.theme-sidebar {
  .p-menuitem-icon {
    font-size: 6px;
  }
}
</style>
