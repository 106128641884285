<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="131" height="34" viewBox="0 0 131 34" fill="none">
    <path
      d="M27.7232 15.7659C27.7232 16.3784 27.7232 17.0554 27.7883 17.7969C27.5605 17.0554 27.3003 16.3784 27.04 15.8626L25.446 12.3809H23.0061L22.8109 15.9915C22.7783 16.604 22.8109 17.2166 22.8434 17.8613C22.6482 17.1843 22.4205 16.5073 22.1928 15.9593L20.6638 12.3809H18.0938L21.965 21.1496H24.7303L24.8279 17.7646C24.8604 17.0876 24.8279 16.3784 24.7303 15.6369C24.9905 16.4751 25.2508 17.2488 25.511 17.8613L26.9099 21.1496H29.8703L30.2932 12.3809H27.8533L27.7232 15.7659Z"
      fill="#29588C"
    />
    <path
      d="M40.1177 18.054C36.2464 15.2493 34.815 10.0912 36.6042 8.57599C27.235 2.96657 23.2987 7.51214 16.3694 6.545C13.2138 6.1259 12.4656 3.67581 4.82056 4.22386L4.78803 4.19162C5.08082 3.93371 5.34107 3.19224 5.27601 2.61195C5.14588 1.16124 3.87714 0.0329118 2.4132 0.000673714C1.04686 -0.0315643 -0.0917534 1.09677 0.00584227 2.61195C0.0383742 3.48238 0.526352 4.44952 1.73003 4.96533C1.86016 5.02981 8.30148 31.3038 9.01718 33.3993H10.904L2.64093 3.837L2.60839 3.80476C1.99029 3.74029 1.50231 3.25672 1.43725 2.64419C1.37218 1.99943 1.86016 1.48362 2.5108 1.48362C3.16144 1.48362 3.74701 1.99943 3.77954 2.64419C3.81207 3.09553 3.58435 3.48238 3.2265 3.67581V3.74029L12.5306 33.4315H14.6127C13.4415 29.9498 8.72439 16.3131 6.2845 8.96285L6.31703 8.93061C8.33401 11.8643 9.76541 14.4111 11.4896 18.731C12.368 20.9232 13.5391 25.4042 14.5476 28.3057L14.9705 27.8543C15.9139 26.9517 17.7032 26.3069 19.2647 26.5971C20.7937 26.6938 20.6311 27.7576 23.4288 28.3057C26.9097 28.9827 28.9592 26.8872 32.7655 26.4681C39.0116 25.7911 44.6071 28.3057 44.7372 28.2734C44.7372 28.2734 44.3468 21.1488 40.1177 18.054ZM32.1474 24.8884C27.9507 25.4687 26.9097 26.9194 24.112 26.726C22.0299 26.5648 21.6721 25.1463 19.9479 24.7917C18.7767 24.5338 17.0526 24.5338 15.3609 25.372C12.2053 11.8643 6.08931 5.93247 6.08931 5.93247C11.2944 4.96533 12.3029 7.80228 17.3128 8.12466C23.8517 8.54375 27.2676 5.28771 34.3595 9.05956C33.1233 11.6386 35.9211 16.7322 38.1332 18.2474C42.2648 21.0844 42.8504 26.049 42.8504 26.0812C42.7528 26.0812 37.873 24.1147 32.1474 24.8884Z"
      fill="#29588C"
    />
    <path
      d="M47.5024 12.4121H50.1375L51.9593 20.1492L53.8136 12.4766H56.2861L58.2054 20.1492L59.8971 12.4121H62.4021L59.3441 23.5342H57.0668L54.9848 15.3135L52.9678 23.5342H50.7556L47.5024 12.4121Z"
      fill="#29588C"
    />
    <path
      d="M63.2153 12.4121H65.4926V14.3786H63.2153V12.4121ZM63.2479 15.4102H65.46V23.5342H63.2479V15.4102Z"
      fill="#29588C"
    />
    <path d="M67.0215 12.4121H69.2337V23.5342H67.0215V12.4121Z" fill="#29588C" />
    <path
      d="M70.7627 12.4121H72.9749V16.4419C73.3002 15.9905 73.6906 15.6681 74.1135 15.4747C74.5364 15.2813 74.9918 15.1523 75.4798 15.1523C76.4232 15.1523 77.1064 15.378 77.5619 15.8616C78.0173 16.3451 78.245 17.1189 78.245 18.1505V23.5342H76.0329V18.4406C76.0329 17.8603 75.9353 17.4735 75.7075 17.2801C75.4798 17.0866 75.187 16.9577 74.8292 16.9577C74.5364 16.9577 74.3087 16.9899 74.1135 17.0866C73.9183 17.1833 73.7231 17.2801 73.5279 17.4412C73.3652 17.6024 73.2677 17.7636 73.1701 17.9571C73.0725 18.1505 73.0074 18.3761 73.0074 18.6341V23.5342H70.7952V12.4121H70.7627Z"
      fill="#29588C"
    />
    <path
      d="M87.1589 20.9884C86.9963 21.8911 86.6384 22.6003 86.0528 23.0517C85.4673 23.5352 84.5889 23.7609 83.4828 23.7609C82.1165 23.7609 81.108 23.374 80.4248 22.6326C79.7416 21.8911 79.3838 20.8272 79.3838 19.441C79.3838 18.764 79.4814 18.1515 79.6766 17.6034C79.8718 17.0554 80.1646 16.604 80.5224 16.2494C80.8803 15.8626 81.3032 15.6047 81.8237 15.4112C82.3117 15.2178 82.8972 15.1211 83.4828 15.1211C84.7515 15.1211 85.695 15.4757 86.3131 16.1849C86.9312 16.8942 87.2565 17.8613 87.2565 19.0864V19.9568H81.5634C81.596 20.6016 81.7586 21.1174 82.0514 21.5042C82.3767 21.8588 82.8322 22.0523 83.4503 22.0523C84.3286 22.0523 84.8491 21.6977 84.9793 20.9562H87.1589V20.9884ZM85.1419 18.5706C85.1419 18.0225 85.0118 17.5712 84.719 17.2488C84.4262 16.9264 84.0033 16.7975 83.4177 16.7975C83.125 16.7975 82.8647 16.8297 82.637 16.9264C82.4093 17.0231 82.2466 17.1521 82.0839 17.3133C81.9213 17.4745 81.8237 17.6679 81.7261 17.8613C81.6285 18.087 81.596 18.3127 81.596 18.5383H85.1419V18.5706Z"
      fill="#29588C"
    />
    <path d="M88.5254 12.4121H90.7376V23.5342H88.5254V12.4121Z" fill="#29588C" />
    <path
      d="M92.2986 15.4112H94.4458V16.5073C94.7385 16.056 95.0964 15.7014 95.5193 15.5079C95.9422 15.3145 96.3651 15.1855 96.8206 15.1855C97.4062 15.1855 97.8941 15.3145 98.2845 15.5402C98.6749 15.7658 98.9352 16.1204 99.0978 16.5718C99.4882 16.0882 99.8786 15.7014 100.301 15.5079C100.724 15.2823 101.18 15.1855 101.668 15.1855C102.514 15.1855 103.164 15.4112 103.587 15.8625C104.01 16.3139 104.238 17.0876 104.238 18.1192V23.5674H102.026V18.4094C102.026 18.1192 101.993 17.8935 101.961 17.7001C101.896 17.5067 101.83 17.3777 101.733 17.281C101.635 17.1843 101.538 17.1198 101.408 17.0554C101.277 16.9909 101.147 16.9909 101.017 16.9909C100.789 16.9909 100.594 17.0231 100.399 17.0876C100.204 17.1521 100.041 17.281 99.8461 17.4744C99.6834 17.6356 99.5858 17.7968 99.4882 17.958C99.3906 18.1514 99.3581 18.3449 99.3581 18.5383V23.5352H97.1459V18.4094C97.1459 18.1192 97.1134 17.8935 97.0808 17.7001C97.0158 17.5067 96.9507 17.3777 96.8531 17.281C96.7555 17.1843 96.6579 17.1198 96.5278 17.0554C96.3977 16.9909 96.2675 16.9909 96.1374 16.9909C95.9097 16.9909 95.7145 17.0231 95.5193 17.0876C95.3241 17.1521 95.1615 17.281 94.9663 17.4744C94.8036 17.6356 94.706 17.7968 94.6084 17.9903C94.5108 18.1837 94.4783 18.4093 94.4783 18.6673V23.5674H92.2661V15.4112H92.2986Z"
      fill="#29588C"
    />
    <path
      d="M107.296 20.9562C107.361 21.4397 107.556 21.7299 107.882 21.8911C108.207 22.0523 108.565 22.1167 109.02 22.1167C109.541 22.1167 109.931 22.0523 110.159 21.9233C110.387 21.7944 110.484 21.5687 110.484 21.2786C110.484 21.0851 110.419 20.8917 110.256 20.7305C110.126 20.5693 109.834 20.4726 109.443 20.4081L108.305 20.2147C107.296 20.0535 106.58 19.7956 106.092 19.4088C105.604 19.0219 105.377 18.4416 105.377 17.7001C105.377 17.3133 105.474 16.9587 105.637 16.6363C105.8 16.3139 106.06 16.056 106.353 15.8303C106.678 15.6047 107.036 15.4435 107.491 15.3145C107.914 15.1856 108.402 15.1211 108.923 15.1211C109.573 15.1211 110.126 15.1856 110.549 15.3145C111.005 15.4435 111.363 15.6047 111.623 15.8303C111.916 16.056 112.111 16.3139 112.241 16.604C112.371 16.8942 112.469 17.2166 112.534 17.5712L110.582 17.8291C110.484 17.4422 110.322 17.1843 110.094 16.9909C109.866 16.8297 109.476 16.733 108.955 16.733C108.662 16.733 108.402 16.7652 108.239 16.7975C108.044 16.8297 107.882 16.8942 107.784 16.9909C107.686 17.0554 107.589 17.1521 107.556 17.2488C107.524 17.3455 107.491 17.4422 107.491 17.5067C107.491 17.7646 107.556 17.958 107.719 18.087C107.882 18.2159 108.207 18.3127 108.662 18.3771L109.703 18.5383C110.289 18.635 110.744 18.764 111.135 18.8929C111.525 19.0219 111.818 19.2153 112.046 19.441C112.273 19.6667 112.436 19.8923 112.501 20.1825C112.599 20.4726 112.631 20.795 112.631 21.1818C112.631 21.9556 112.338 22.5681 111.753 23.0194C111.167 23.4707 110.256 23.6964 109.02 23.6964C108.532 23.6964 108.044 23.6642 107.621 23.5675C107.198 23.4707 106.776 23.3418 106.418 23.1161C106.06 22.8905 105.767 22.6003 105.539 22.2457C105.312 21.8911 105.181 21.4397 105.149 20.8917H107.296V20.9562Z"
      fill="#29588C"
    />
    <path
      d="M121.252 20.9884C121.09 21.8911 120.732 22.6003 120.146 23.0517C119.561 23.5352 118.682 23.7609 117.576 23.7609C116.21 23.7609 115.201 23.374 114.518 22.6326C113.835 21.8911 113.477 20.8272 113.477 19.441C113.477 18.764 113.575 18.1515 113.77 17.6034C113.965 17.0554 114.258 16.604 114.616 16.2494C114.974 15.8626 115.396 15.6047 115.917 15.4112C116.405 15.2178 116.99 15.1211 117.576 15.1211C118.845 15.1211 119.788 15.4757 120.406 16.1849C121.024 16.8942 121.35 17.8613 121.35 19.0864V19.9568H115.657C115.689 20.6016 115.852 21.1174 116.145 21.5042C116.47 21.8588 116.925 22.0523 117.544 22.0523C118.422 22.0523 118.942 21.6977 119.073 20.9562H121.252V20.9884ZM119.235 18.5706C119.235 18.0225 119.105 17.5712 118.812 17.2488C118.519 16.9264 118.097 16.7975 117.511 16.7975C117.218 16.7975 116.958 16.8297 116.73 16.9264C116.503 17.0231 116.34 17.1521 116.177 17.3133C116.015 17.4745 115.917 17.6679 115.819 17.8613C115.722 18.087 115.689 18.3127 115.689 18.5383H119.235V18.5706Z"
      fill="#29588C"
    />
    <path
      d="M122.619 15.4112H124.766V16.5395C125.091 16.056 125.481 15.7014 125.904 15.5079C126.36 15.2823 126.815 15.1855 127.303 15.1855C128.247 15.1855 128.93 15.4112 129.385 15.8948C129.841 16.3784 130.068 17.1521 130.068 18.1837V23.5674H127.856V18.4416C127.856 17.8613 127.759 17.4744 127.531 17.281C127.303 17.0876 127.01 16.9586 126.653 16.9586C126.36 16.9586 126.132 16.9909 125.937 17.0876C125.742 17.1843 125.547 17.281 125.351 17.4422C125.189 17.6034 125.091 17.7646 124.993 17.958C124.896 18.1514 124.831 18.3771 124.831 18.635V23.5352H122.619V15.4112Z"
      fill="#29588C"
    />
  </svg>
</template>
