import { defineStore } from "pinia";

import state from "@/stores/port-call/port-call.state";
import getters from "@/stores/port-call/port-call.getters";
import actions from "@/stores/port-call/port-call.actions";

import { PortCallStoreDefinition } from "@@/stores/modules/PortCallTypes.ts";

const usePortCallStore: PortCallStoreDefinition = defineStore("port-call", {
  state,
  getters,
  actions,
});

export default usePortCallStore;
