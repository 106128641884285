// import { PaginationType } from "@@/CommonTypes.ts";

import { CustomerType, NonNullableFields } from "@@/CommonTypes.ts";

// export PortCallTableTypes
export type PortCallTableTypes = "upcoming" | "completed";

export enum Status {
  // PDA_SUBMITTED
  PROFORMA = "PROFORMA", // AKA action-required
  APPROVED = "APPROVED", // AKA submitted

  // FDA_SUBMITTED
  PENDING_DATA = "PENDING_DATA", // AKA pending-data
  FINAL = "FINAL", // AKA completed
}

export enum StatusTransform {
  // PDA_SUBMITTED
  PROFORMA = "Action required",
  APPROVED = "Submitted",

  // FDA_SUBMITTED
  PENDING_DATA = "Pending data",
  FINAL = "Completed",
}

export enum StatusSub {
  UPCOMING = "PDA_SUBMITTED",
  FINISHED = "FDA_SUBMITTED",

  upcoming = "PDA_SUBMITTED",
  finished = "FDA_SUBMITTED",

  PDA_SUBMITTED = "PDA_SUBMITTED", // UPCOMING
  FDA_SUBMITTED = "FDA_SUBMITTED", // FINISHED (COMPLETED)
}

export enum StatusSubTitle {
  PDA_SUBMITTED = "Active",
  FDA_SUBMITTED = "Completed",
}

export enum StatusSubShort {
  PDA_SUBMITTED = "pda",
  FDA_SUBMITTED = "fda",
}

export enum SAFETY {
  audited = "Audited",
  Audited = "Audited",
}

export enum StatusMessageEnum {
  // PDA_SUBMITTED
  PROFORMA = "warning",
  APPROVED = "success",

  // FDA_SUBMITTED
  PENDING_DATA = "secondary",
  FINAL = "success",
}

export type PortType = {
  id: number;
  name: string;
  ext_id: string;
};

export type VesselType = {
  id: number;
  vessel_name: string;
  ext_id?: string;
  vessel_imo?: string;
  vessel_managers?: Array<VesselManagersFilterType>;
};

export type VesselManagersFilterType = {
  email: string;
  id: number;
  first_name: string;
  last_name: string;
  vessel_manager_name?: string;
};

export type VesselManagerType = {
  id: number;
  name: string;
  ext_id: string;
};

export type ExpenseGroupLine = {
  id: number;
  other_service_description: string;
  proforma_amount_customer_currency: number | null;
  total_amount_customer_currency: number | null;
};

export enum AmountCustomerCurrencyEnum {
  PDA_SUBMITTED = "proforma_amount_customer_currency",
  FDA_SUBMITTED = "total_amount_customer_currency",
}

export type AdditionalCost = {
  id: number;
  expense_group_name: string;
  expense_group_lines: Array<ExpenseGroupLine>;
};

export enum OperationalServiceEnum {
  numberOfOnSigners = "on-signers",
  numberOfOffSigners = "off-signers",
}

export type OperationalServiceType = {
  numberOfOnSigners: number;
  numberOfOffSigners: number;
};

export type OperationalServicesType = {
  [p: string]: OperationalServiceType[];
};

export type PortCallType = {
  additional_costs: Array<AdditionalCost>;
  anchorage: string;
  // created: string;
  customer: CustomerType;
  customer_currency_code: string;
  da_id: string | null;
  emission_potential: number | null; // crew_change_emissions
  est_arrival_date: string | null;
  est_departure_date: string | null;
  ext_id: string | null;
  id: number;
  // modified: string;
  port: string;
  port_call_name: string | null;
  port_call_type: string | null;
  status_sub: StatusSub;
  status: Status;
  total_amount_customer_currency: number | null;
  // total_amount_usd: number | null;
  vessel_name: string;
  vessel_imo: string;
  suppliers: SuppliersType;
  operational_services: OperationalServicesType;
  tool_tip: ToolTipType;
  reduced_emissions: number;
  abatement_cost: number;
  potential_reduced_emissions_percentage: number;
};

export type ToolTipEstimationType = {
  option: string;
  unit: number;
};

export type ToolTipCommentType = {
  supplier_name: string;
  comment: number;
};

/**
 * In near future, we will have to use this type instead of SuppliersType
 *
 * export type ToolTipType = { [k in PortOptionEnum]?: Array<ToolTipEstimationType> };
 * or,
 * export type ToolTipType = Partial<GenericInPortOptionEnum<Array<ToolTipEstimationType>>>;
 */
export type ToolTipType = {
  [StatusSubShort.PDA_SUBMITTED]: Partial<GenericInPortOptionEnum<Array<ToolTipEstimationType>>>;
  [StatusSubShort.FDA_SUBMITTED]: Partial<GenericInPortOptionEnum<Array<ToolTipCommentType>>>;
};

export type PortCallFormType = Pick<
  PortCallType,
  "da_id" | "customer" | "vessel_name" | "port" | "est_arrival_date"
> & {
  suppliers: Array<keyof SuppliersType>;
};

export type UpcomingPortCallType = Pick<
  PortCallType,
  "id" | "ext_id" | "da_id" | "est_arrival_date" | "status"
> & {
  emission_potential?: string;
  port: string;
  vessel_imo?: string;
  vessel_name: string;
};

export type CompletedPortCallType = Pick<
  PortCallType,
  "id" | "ext_id" | "da_id" | "est_departure_date" | "status"
> & {
  emission_potential?: string;
  port: string;
  vessel_imo?: string;
  vessel_name: string;
};

export type UpcomingPortCallResponseType = PaginatedResponseType<UpcomingPortCallType>;
export type CompletedPortCallResponseType = PaginatedResponseType<CompletedPortCallType>;

export type CustomersResponseType = PaginatedResponseType<CustomerType>;

export type PaginatedResponseType<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<T>;
};

export type SupplierCompanyType = {
  id: number | null;
  supplier_name: string;
  cost: number;
  safety: SAFETY;
  emission: number;
  selected: boolean;
  show_options: boolean;
};

export type SupplierCompaniesType = Array<SupplierCompanyType>;

/**
 * In near future, we will have to use this type instead of SuppliersType
 *
 * export type SuppliersType = { [k in PortOptionEnum]?: SupplierCompaniesType };
 * or,
 * export type SuppliersType = Partial<GenericInPortOptionEnum<SupplierCompaniesType>>;
 */
export type SuppliersType = {
  [key: string]: SupplierCompaniesType;
};

export type SupplierCompanyPatchType = NonNullableFields<
  Pick<SupplierCompanyType, "id" | "selected">
>;
export type SuppliersPatchType = {
  [key: string]: Array<SupplierCompanyPatchType>;
};
export type KPIRequestType = Omit<
  EmissionReductionPotentialType,
  "emission_reduction_potential"
> & {
  crew_change_emissions: PortCallType["emission_potential"];
  total_emission_reduction_potential: number;
};
export type EmissionReductionPotentialType = Pick<
  PortCallType,
  "reduced_emissions" | "abatement_cost" | "potential_reduced_emissions_percentage"
> & {
  emission_reduction_potential: number;
};
export type EmissionReductionPotentialsType = Partial<
  GenericInPortOptionEnum<EmissionReductionPotentialType>
>;
export type ApprovePortCallRequestType = KPIRequestType & {
  suppliers: SuppliersPatchType;
  emission_reduction_potentials: EmissionReductionPotentialsType;
};

export type GenericInPortOptionEnum<T> = {
  [k in PortOptionEnum]: T;
};

/*export type DefaultSuppliersCompanyType = {
  [key: string]: SupplierCompanyType | SupplierCompaniesType;
};*/
export type DefaultSuppliersCompanyType = Partial<
  GenericInPortOptionEnum<SupplierCompanyType | SupplierCompaniesType>
>;

export enum ServiceTypeNameEnum {
  "transportation" = "transportation",
  "accommodation" = "accommodation",
  "launch_hire" = "launch_hire",
}

export enum ServiceTypeEnum {
  "transportation_opt" = "transportation_opt",
  "accommodation_opt" = "accommodation_opt",
  "launch_hire_opt" = "launch_hire_opt",
  "transportation" = "transportation_opt",
  "accommodation" = "accommodation_opt",
  "launch_hire" = "launch_hire_opt",
}

export enum PortOptionEnum {
  "transportation_opt" = "Land Transportation",
  "accommodation_opt" = "Accommodation",
  "launch_hire_opt" = "Launch Hire",
}

export enum PortOptionUnitStringEnum {
  "Land Transportation" = "trip",
  "Accommodation" = "room",
  "Launch Hire" = "trip",
}

//-----------------
export type PortOptionType = {
  id: number;
  name: string;
  distance?: number;
};

export type PortOptionsOnlyType = {
  transportation_opt: PortOptionType[];
  accommodation_opt: PortOptionType[];
  launch_hire_opt: PortOptionType[];
};

export type PortOptionResponseType = {
  id: number;
  name: string;
  ext_id: string;
  recipient_email: string;
} & PortOptionsOnlyType;
//-----------------

//-----------------
export type AgentPortDataOptionsType = {
  id: number;
  transportation_opt: Required<PortOptionType> | null;
  accommodation_opt: Required<PortOptionType> | null;
  launch_hire_opt: Required<PortOptionType> | null;
  unit_value: number;
};

export type SupplementaryDataType = {
  id: number;
  da_id: string;
  port: PortType;
  agent_port_data_options: AgentPortDataOptionsType[];
};
//-----------------

//-----------------
export type SupplierServiceType = {
  id: number;
  name?: string;
  identifier?: string;
  fuel_type?: string;
  option?: string;
};
export type SupplierType = {
  id: number;
  name: string;
  ext_id: string;
  accommodations: SupplierServiceType[];
  transportations: SupplierServiceType[];
  launch_hires: SupplierServiceType[];
};
export type ExpenseGroupLineOptionType = {
  id: number;
  service_type: ServiceTypeEnum;
  total_amount: number;
  suppliers: SupplierType[];
};

export type FDAPortCallDataType = {
  id: number;
  da_id: string;
  est_departure_date: string;
  local_currency: string;
  customer: CustomerType;
  port: PortType;
  vessel: VesselType;
};
export type ExpenseGroupLineOptionResponseType = {
  draft_options: ExpenseGroupLineOptionsRequestType;
  fda_port_call_data: FDAPortCallDataType;
  expense_group_line_option: ExpenseGroupLineOptionType[];
};
export type ExpenseGroupLineOptionRequestOptionType = {
  accommodation: number | null;
  transportation: number | null;
  launch_hire: number | null;
  other: boolean;
  comment: string;
  cost_local_currency: number | null;
};

export type ExpenseGroupLineOptionRequestType = {
  expense_group_line: number;
  supplier: number | null;
  non_crew_change?: boolean;
  option: Array<ExpenseGroupLineOptionRequestOptionType>;
};

export type ExpenseGroupLineOptionsRequestType = Array<ExpenseGroupLineOptionRequestType>;
//-----------------

//-----------------
export type PortOptionsFormSelectType2 = {
  id: number;
  transportation_opt?: number;
  accommodation_opt?: number;
  launch_hire_opt?: number;
  unit_value: number;
} & PortOptionType;

export type PortOptionsFormSelectType = {
  transportation_opt?: PortOptionsFormSelectType2[];
  accommodation_opt?: PortOptionsFormSelectType2[];
  launch_hire_opt?: PortOptionsFormSelectType2[];
};

export type PortOptionsFormType = {
  id: number;
  transportation_opt?: number;
  accommodation_opt?: number;
  launch_hire_opt?: number;
  unit_value: number;
};

export type PortOptionsFormOnlyType = {
  transportation_opt: PortOptionsFormType[];
  launch_hire_opt: PortOptionsFormType[];
  accommodation_opt: PortOptionsFormType[];
};
//-----------------

//-----------------
export type PortDataOptionsRequestType = {
  id?: number | null;
  transportation_opt: number | null;
  accommodation_opt: number | null;
  launch_hire_opt: number | null;
  unit_value: number | null;
};
export type AgentPortDataOptionsRequestType = {
  id?: number | null;
  transportation_opt: number | null;
  accommodation_opt: number | null;
  launch_hire_opt: number | null;
  unit_value: number | null;
};

export type SupplementaryDataRequestType = {
  da_id: string;
  port: PortType["id"];
  agent_port_data_options: AgentPortDataOptionsRequestType[];
};
//-----------------

export type PdaFdaTotalType = {
  pda: number;
  fda: number;
};

export type TotalCostAndChangeEmissionsType = {
  totalCost: PdaFdaTotalType;
  crewChangeEmissions: PdaFdaTotalType;
};

export type ReducedEmissionsAndAbatementCostType = {
  reducedEmissions: PdaFdaTotalType;
  abatementCost: PdaFdaTotalType;
};

export type SubmissionLabelTypes = {
  [key in keyof typeof ServiceTypeEnum]?: Partial<
    Record<
      | "vehicleLabel"
      | "valueLabel"
      | "valuePlaceholder"
      | "supplierPlaceholder"
      | "vehiclePlaceholder"
      | "costPlaceholder"
      | "optionLabel"
      | "optionPlaceholder",
      string
    >
  >;
};

export type HeaderTitlesType = Record<
  StatusSub,
  {
    "step-1": string;
    "step-2": string;
  }
>;

export enum BackButtonEnum {
  "ArrowBack" = "arrow-back",
  "BackButton" = "back-button",
}
