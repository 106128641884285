<template>
  <Menubar :model="items" class="top-menubar">
    <template #start>
      <div class="flex gap-4">
        <RouterLink
          :to="isAgent() ? { name: 'AgentDashboard' } : { name: 'Dashboard' }"
          class="flex m-auto"
        >
          <WilhelmsenLogo />
        </RouterLink>
        <div class="port-service-title">Port Services</div>
        <CustomerSelect
          v-if="userCanAccessCustomer && isCustomerListAccessible && dashboardStore.customer_id"
          :disabled="isCustomerListDisabled"
          :wait-to-click-continue="false"
          class="customer-select md:w-12rem lg:w-14rem xl:w-16rem"
        />
      </div>
    </template>
    <template #end>
      <div class="flex align-items-center gap-2">
        <div
          :style="{ visibility: siteStateStore.isOffline ? 'visible' : 'hidden' }"
          class="text-orange-500 mr-2"
          style="cursor: default"
          title="You are offline"
        >
          <i class="material-icons material-icons-round">wifi_off</i>
        </div>
        <div
          :style="{ display: siteStateStore.getDataSeed ? 'block' : 'none' }"
          class="text-gray-500 mr-2"
          style="cursor: default"
          title="You are with demo data"
          @click="siteStateStore.setDataSeed(false)"
        >
          <i class="material-icons material-icons-round">data_object</i>
        </div>

        <div class="sub-titles">
          <template v-for="(attributes, title) in subTitle" :key="title">
            <component
              :is="attributes.isRoute ? 'RouterLink' : 'span'"
              v-if="attributes.permission"
              :class="[getSlug(title), attributes.className]"
              :to="{ name: title }"
              class="statistics mr-5 no-underline text-gray-600"
            >
              {{ title }}
            </component>
          </template>
        </div>

        <div class="hidden">
          <LocaleSelector />
        </div>
        <ProfileDropdown />
      </div>
    </template>
  </Menubar>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import Menubar from "primevue/menubar";

import WilhelmsenLogo from "@/components/icons/WilhelmsenLogo.vue";
import LocaleSelector from "@/components/common/LocaleSelector.vue";
import ProfileDropdown from "@/components/common/ProfileDropdown.vue";
import CustomerSelect from "@/components/common/CustomerSelect.vue";

import { auth0 } from "@/services/auth.service.ts";
import { isAgent, isVesselManagerOrESGManager } from "@/permission.ts";
import { getSlug } from "@/utilities.ts";

import { useDashboardStore, useSiteStateStore } from "@/stores";

import type { MenuItem } from "primevue/menuitem";
import { User } from "@auth0/auth0-vue";
import { USER_ROLES } from "@@/AuthTypes.ts";
import DashboardStatisticsController from "@/controllers/dashboard/DashboardStatisticsController.ts";

const siteStateStore = useSiteStateStore();
const dashboardStore = useDashboardStore();

const router = useRouter();

const items = ref<Array<MenuItem>>([]);
const customerID = ref<number | null>(NaN);

onMounted(async () => {
  if (
    (typeof router.currentRoute.value.name == "string" && isCustomerListAccessible.value) ||
    isAgent()
  ) {
    await fetchCustomers();
  }

  customerID.value = DashboardStatisticsController.getCustomerID();

  if (userCanAccessCustomer.value) {
    dashboardStore.customer_id = customerID.value ?? dashboardStore.customer_id;
    if (dashboardStore.customer_id) {
      localStorage.setItem("customer_id", JSON.stringify(dashboardStore.customer_id));
    }
    return;
  }

  localStorage.removeItem("customer_id");
  localStorage.removeItem("selectedCustomer");
});

const isCustomerListAccessible = computed(() =>
  ["Dashboard", "StatusPortCallID"].includes(router.currentRoute.value.name as string),
);
const isCustomerListDisabled = computed(() =>
  ["StatusPortCallID"].includes(router.currentRoute.value.name as string),
);
const userCanAccessCustomer = computed<boolean>(() => {
  const user = auth0.user.value as User;
  return (
    user &&
    (user?.can_access_customer ||
      ["System Administrator", "Agent"].includes(USER_ROLES[user?.user_role]))
  );
});

const subTitles = computed(() => ({
  Dashboard: {
    Dashboard: {
      className: "font-bold text-primary-500",
      isRoute: true,
      permission: true,
    },
    Statistics: {
      className: "",
      isRoute: true,
      permission: isVesselManagerOrESGManager(),
    },
  },
  Statistics: {
    Dashboard: {
      className: "",
      isRoute: true,
      permission: true,
    },
    Statistics: {
      className: "font-bold text-primary-500",
      isRoute: true,
      permission: isVesselManagerOrESGManager(),
    },
  },
  StatusPortCallID: {
    Dashboard: {},
    Statistics: {},
  },
  AgentDashboard: {},
  StatusPortCallAdditionalForm: {},
}));
const subTitle = computed(() => subTitles.value[router.currentRoute.value.name as string] || {});

const fetchCustomers = async (param = {}) => {
  if (!userCanAccessCustomer.value) {
    return;
  }

  // initial fetch
  const response = await dashboardStore.fetchCustomersAction({
    ...param,
    page: 1,
    page_size: DashboardStatisticsController.customerPageSize,
  });

  return response;
};

/**
 * Set showSelectCustomerDialog
 * ---
 * - If the route is dashboard.
 * - If there is no customer selected.
 */
const setShowSelectCustomerDialog = () => {
  customerID.value = DashboardStatisticsController.getCustomerID() ?? customerID.value;
  dashboardStore.show_select_customer_dialog =
    !customerID.value && router.currentRoute.value.name === "Dashboard";
};

watch(
  () => userCanAccessCustomer.value,
  (newCanAccessCustomer) => {
    dashboardStore.can_access_customer = newCanAccessCustomer;
  },
  {
    immediate: true,
  },
);

watch(
  () => [customerID.value, dashboardStore.can_access_customer],
  ([, newCanAccessCustomer]) => {
    if (!newCanAccessCustomer) {
      dashboardStore.show_select_customer_dialog = false;
      siteStateStore.apiCallable = true;
      return;
    }

    setShowSelectCustomerDialog();
  },
  {
    immediate: true,
  },
);
</script>
