import { PortCallState } from "@@/stores/modules/PortCallTypes.ts";
import {
  PortCallFormType,
  PortCallType,
  Status,
  StatusSub,
  StatusSubShort,
} from "@@/PortCallsTypes.ts";

export const defaultCurrency = "USD";
export const defaultPortCall: PortCallType = {
  additional_costs: [],
  anchorage: "",
  //created: "",
  customer: {
    id: NaN,
    customer_name: "",
  },
  customer_currency_code: "",
  da_id: "",
  emission_potential: 0,
  est_arrival_date: "",
  est_departure_date: "",
  ext_id: "",
  id: NaN,
  // modified: "",
  port_call_name: "",
  port_call_type: "",
  port: "",
  status_sub: StatusSub.PDA_SUBMITTED,
  status: Status.PROFORMA,
  total_amount_customer_currency: NaN,
  // total_amount_usd: null,
  vessel_name: "",
  vessel_imo: "",
  suppliers: {},
  operational_services: {},
  tool_tip: {
    [StatusSubShort.PDA_SUBMITTED]: {},
    [StatusSubShort.FDA_SUBMITTED]: {},
  },
  reduced_emissions: 0,
  abatement_cost: 0,
  potential_reduced_emissions_percentage: 0,
};
export const defaultPortFormCall: PortCallFormType = {
  customer: {
    id: NaN,
    customer_name: "",
  },
  est_arrival_date: "",
  da_id: "",
  port: "",
  vessel_name: "",
  suppliers: [],
};

// use snake_case for state keys
export default (): PortCallState => ({
  portCall: defaultPortCall,
  portCallForm: defaultPortFormCall,
  dataSeed: false,
  emissionReductionPotentials: {},
  defaultSuppliersCompany: {},
});
