import { defineStore } from "pinia";
import { usePortCallStore } from "@/stores";

import {
  SiteStateState,
  RefreshType,
  SiteStateStoreDefinition,
} from "@@/stores/modules/SiteStateTypes.ts";

const defaultRefreshTimeOut = 43200000; // 12 hours
// const defaultRefreshTimeOut = 5000; // 5 seconds

const useSiteStateStore: SiteStateStoreDefinition = defineStore("site-state", {
  state: (): SiteStateState => ({
    loading: false,
    refresh: { isRequired: false, timeOut: defaultRefreshTimeOut, start: 0, end: 0 },
    offline: false,
    dataSeed: false,
    dateFormat: "dd.MM.yyyy HH:mm",
    undefinedStr: "-",
    titleReplace: {},
    apiCallable: true,
    isContainer: true,
  }),
  getters: {
    isLoading(state: SiteStateState): boolean {
      return state.loading;
    },
    isOffline(state: SiteStateState): boolean {
      return state.offline;
    },
    getDataSeed(state: SiteStateState): boolean {
      return state.dataSeed;
    },
    getDateFormat(state: SiteStateState): string {
      return state.dateFormat;
    },
    getUndefinedStr(state: SiteStateState): string {
      return state.undefinedStr;
    },
    getRefreshRequire(state: SiteStateState): RefreshType["isRequired"] {
      return state.refresh.isRequired;
    },
    getRefresh(state: SiteStateState): RefreshType {
      return state.refresh;
    },
    getIsContainer(state: SiteStateState): boolean {
      return state.isContainer;
    },
  },
  //since we rely on `this`, we cannot use an arrow function
  actions: {
    setIsLoading(value: boolean) {
      this.loading = value;
    },
    setIsOffline(value: boolean) {
      this.offline = value;
    },
    setDataSeed(value: boolean) {
      const portCallStore = usePortCallStore();

      this.dataSeed = value;
      portCallStore.setDataSeed(value);
    },
    setDateFormat(value: string) {
      this.dateFormat = value;
    },
    setRefreshRequire(value: number) {
      this.refresh.isRequired = value > this.refresh.end;
    },
    setRefreshTimeout(value: RefreshType["timeOut"] = defaultRefreshTimeOut) {
      this.refresh.timeOut = value;

      this.refresh.start = Date.now();
      this.refresh.end = this.refresh.start + this.refresh.timeOut;
    },
    setIsContainer(value: boolean) {
      this.isContainer = value;
    },
  },
});

export default useSiteStateStore;
