export type StatisticsResponse = {
  months: MonthStatistics;
  ports: EmissionDataEntries;
  vessels: EmissionDataEntries;
  supplier_categories: {
    launch_hire: SupplierCategoryData;
    accommodation: SupplierCategoryData;
    land_transport: SupplierCategoryData;
  };
  suppliers: {
    [key: string]: SupplierData;
  };
};

export type EmissionDataEntries = {
  [key: string]: EmissionData;
};

export type MonthStatistics = {
  Jan: EmissionData | null;
  Feb: EmissionData | null;
  Mar: EmissionData | null;
  Apr: EmissionData | null;
  May: EmissionData | null;
  Jun: EmissionData | null;
  Jul: EmissionData | null;
  Aug: EmissionData | null;
  Sep: EmissionData | null;
  Oct: EmissionData | null;
  Nov: EmissionData | null;
  Dec: EmissionData | null;
};
export type MonthOverYearsStatistics = {
  [year:string]: MonthStatistics
}

export const MonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export type EmissionData = {
  name: string;
  sum_p13_based_emission: number;
  sum_cost_based_emission: number;
  sum_total_cost_usd: number;
  sum_cost_launch_hire: number;
  sum_cost_accommodation: number;
  sum_cost_land_transport: number;
  sum_p13_based_emission_launch_hire: number;
  sum_p13_based_emission_accommodation: number;
  sum_p13_based_emission_land_transport: number;
  sum_reduced_emission: number;
  sum_total_cost_of_reduced_emissions_usd: number;
  mean_abatement_cost: number | null;
  total_base_emission: number;
  port_calls_count: number;
  score_p13_based_emission: number;
  norm_p13_based_emission: number;
};

type SupplierCategoryData = {
  sum_p13_based_emission: number;
  sum_cost_based_emission: number;
  sum_total_cost_usd: number;
};

export type SupplierData = {
  name: string;
  category: string;
  location: string;
  sum_p13_based_emission: number;
  sum_cost_based_emission: number;
  sum_total_cost_usd: number;
};

export enum SupplierTypes {
  launch_hire = "Launch hire",
  accommodation = "Accommodation",
  land_transport = "Land transport",
}
