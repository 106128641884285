import { Context } from "chartjs-plugin-datalabels";
import { ChartDataType, ChartStackType, STACKS } from "@@/ChartTypes.ts";
import {
  // ChartType,
  defaults,
  InteractionItem,
  Point,
  Tooltip,
  // TooltipModel,
  TooltipPosition,
} from "chart.js";
import { format, lastDayOfMonth, lastDayOfYear, startOfYear, subMonths } from "date-fns";
import { MonthNames, MonthStatistics, EmissionData, EmissionDataEntries } from "@@/StatisticsTypes";

export const ChartDatasetsLabelHeight = {
  beforeInit: (chart) => {
    // Get a reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call the original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in other answers
      this.height += 20;
    };
  },
};

export const stackedChartDataLabelsFormatter = (
  _: string,
  ctx: Context,
  stacks: ChartStackType,
) => {
  const datasets = ctx.chart.data.datasets; // Tried `.filter(ds => !ds._meta.hidden);` without success
  const stack = ctx.dataset.stack as ChartDataType["datasets"][0]["stack"];
  const stackLength = datasets.length / stacks[stack].count;
  const isActive = ctx.active;

  // console.log("ctx", ctx);

  if (isActive && (ctx.datasetIndex % stacks[stack].count) + 1 === stacks[stack].count) {
    let sum = 0;

    for (let i = ctx.datasetIndex; i >= ctx.datasetIndex - stackLength; i--) {
      sum += +(datasets[i].data[ctx.dataIndex] ?? 0);
    }

    return sum; /*.toLocaleString("en-US")*/
  } else {
    return "";
  }
};

export const barChartDataLabelsFormatter = (_: string, ctx: Context) => {
  const isActive = ctx.active;
  const val = +(ctx.dataset.data[ctx.dataIndex] ?? 0);

  if (isActive) {
    return parseFloat(val.toFixed(3));
  } else {
    return "";
  }
};

export const barStackedCentered = () => {
  Tooltip.positioners["bar-stacked-center"] = function (
    items: InteractionItem[],
    eventPosition: Point,
  ): TooltipPosition | false {
    if (!items?.length) {
      return false;
    }

    const average = Tooltip?.positioners?.average as unknown as (
      items: InteractionItem[],
      eventPosition: Point,
    ) => Point;

    // const tooltip = this as unknown as TooltipModel<ChartType>;
    const pos = average(items, eventPosition);
    // const xAlign = tooltip?.xAlign ?? "";
    const element = items?.[0]?.element as unknown as Element & {
      width: number;
      height: number;
    };

    return {
      x: pos.x /* + (xAlign === "right" ? -1 : 1) * ((element?.width ?? 0) / 2)*/,
      y: pos.y + (element?.height ?? 0) / 2,
      // xAlign: "center",
      yAlign: "center",
    };
  };
};

export const stacks: ChartStackType = {
  [STACKS.cost]: { label: STACKS.cost, count: 3 },
  [STACKS.emission]: { label: STACKS.emission, count: 3 },
};

export const initChartJS = () => {
  const documentStyle = getComputedStyle(document.documentElement);

  const neutralBlack = documentStyle.getPropertyValue("--neutral-black");

  defaults.font = {
    ...defaults.font,
    family: "Roboto", // Aktiv Grotesk
    size: 12,
  };

  defaults.color = neutralBlack;

  defaults.layout.padding = 10;
};

export const getDropdownToAPIParam = (dateRange = "2023"): string => {
  switch (dateRange) {
    case "last-month":
      return getLastXMonths(1);
    case "last-6-months":
      return getLastXMonths(6);
    case "year-to-date":
      return getThisYearToDate();
    default:
      return getXFullYear(dateRange);
  }
};

const getLastXMonths = (_subMonths: number, _format = "yyyy-MM-d") => {
  const newDate = new Date();

  const lastMonth = subMonths(newDate, _subMonths);
  const lastDayOfLastMonth = lastDayOfMonth(lastMonth);
  const firstDayOfLastMonth = new Date(
    lastDayOfLastMonth.getFullYear(),
    lastDayOfLastMonth.getMonth(),
    1,
  );

  return `${format(firstDayOfLastMonth, _format)},${format(lastDayOfLastMonth, _format)}`;
};

const getThisYearToDate = (_format = "yyyy-MM-d") => {
  const newDate = new Date();

  const _date = startOfYear(newDate);

  return `${format(_date, _format)},${format(newDate, _format)}`;
};

const getXFullYear = (_year: string, _format = "yyyy-MM-d") => {
  const newDate = new Date(_year);

  const firstDay = startOfYear(newDate);
  const lastDay = lastDayOfYear(newDate);

  return `${format(firstDay, _format)},${format(lastDay, _format)}`;
};

export const convertMonthesDataToDataset = (
  monthsData: MonthStatistics,
  field:
    | "sum_total_cost_usd"
    | "sum_p13_based_emission"
    | "median_p13_based_emission"
    | "median_total_cost_usd"
    | "norm_p13_based_emission",
  fillNullValues = false,
) =>
  MonthNames.map((monthName) => {
    const data = monthsData[monthName];
    return data ? data[field] : fillNullValues ? 0 : null;
  }) ?? [];

export const getDatasetFromEmissionDataArray = (
  data: EmissionData[],
  field: "sum_total_cost_usd" | "sum_p13_based_emission",
) => {
  if (!data || data.every((element) => element === null)) {
    return [];
  }
  return data.map((x) => (x !== null ? x[field] : null));
};

export const getPortsLabels = (data: EmissionData[]) => {
  // visualisation requires at least 4 values to properly display the chart
  const minValues = 4;
  const labels = data.map((x) => x?.name ?? "") ?? [];
  while (labels.length < minValues) {
    labels.push("");
  }
  return labels;
};

export const calculateMinMax = (data: number[], tolerance = 10) => {
  const min = Math.min(0, ...data);
  let max = Math.max(...data);
  max = Math.ceil(max + (max * tolerance) / 100);

  return [min, max];
};

export const convertToEmissionEntriesToOptions = (data: EmissionDataEntries | undefined) => {
  if (!data) return null;
  const keys = Object.keys(data);

  if (keys.length === 0 || keys.every((key) => data[key] === null)) {
    return null;
  }

  return Object.keys(data)
    .filter((id) => data[id] !== null)
    .map((id) => ({
      id,
      name: data[id].name,
    }));
};
export const withTotalBaseEmission = (data: EmissionDataEntries) => Object.values(data)
  .filter(x => x !== null)
  .map((x) => {
    x.total_base_emission = x.sum_cost_launch_hire + x.sum_cost_accommodation + x.sum_cost_land_transport;
    return x;
  });
