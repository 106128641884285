export enum HTTP_DATA {
  GET_PORT_CALL_BY_ID = "/v1/port-call",
  GET_UPCOMING_PORT_CALLS = "/v1/port-calls?status_sub=PDA_SUBMITTED",
  GET_COMPLETED_PORT_CALLS = "/v1/port-calls?status_sub=FDA_SUBMITTED",
  GET_PORT_CALL_ADDITIONAL_INFO_BY_ID = "/v1/port-call/{id}/additionalinfo",
  GET_PORT_CALL_OPTIONS_BY_ID = "/v1/port-options/{id}",
  GET_PORT_CALL_SUPPLEMENTARY_DATA_BY_DA_ID = "/v1/supplementary-data/{da_id}",
  GET_PORT_CALL_EXPENSE_GROUP_LINE_OPTION_BY_DA_ID = "/v1/fda-expense-group-line-option/{da_id}",

  CREATE_PORT_CALL_SUPPLEMENTARY = "/v1/supplementary-create-update",
  CREATE_PORT_CALL_ACTUAL_INFO = "/v1/fda-actual-info-create/{da_id}",
  DOWNLOAD_FDA_CSV = "/v1/export-final-port-call",
}
