import { StatisticsState } from "@@/stores/modules/StatisticsTypes.ts";
import {
  CostAndEmissionsPerPortResponseType,
  CostAndEmissionsPerPortSingleStack,
  TotalMonthlyCostAndEmissionsResponseType,
} from "@@/ChartTypes.ts";

const defaultCostAndEmissionsPerPort: CostAndEmissionsPerPortResponseType = {
  labels: [],
  datasets: {
    cost: {
      launch_hire: [],
      transportation: [],
      accommodation: [],
    },
    emission: {
      launch_hire: [],
      transportation: [],
      accommodation: [],
    },
  },
};
const defaultFDACostAndEmissionsPerPort: CostAndEmissionsPerPortSingleStack = {
  labels: [],
  datasets: {
    cost: [],
    emission: [],
  },
};
const defaultTotalMonthlyCostAndEmissions: TotalMonthlyCostAndEmissionsResponseType = {
  labels: [],
  datasets: {
    cost: [],
    emission: [],
  },
};

export default (): StatisticsState => ({
  cost_and_Emissions_per_port: defaultCostAndEmissionsPerPort,
  fda_cost_and_Emissions_per_port: defaultFDACostAndEmissionsPerPort,
  total_monthly_cost_and_emissions: defaultTotalMonthlyCostAndEmissions,
  fda_total_monthly_cost_and_emissions: defaultTotalMonthlyCostAndEmissions,
});
