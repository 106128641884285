export const userAgentData = () => {
  return {
    isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    isDesktop: !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    isMac: /Mac/i.test(navigator.userAgent),
    isWindows: /Windows/i.test(navigator.userAgent),
    isLinux: /Linux/i.test(navigator.userAgent),
    isAndroid: /Android/i.test(navigator.userAgent),
    isChrome: /Chrome/i.test(navigator.userAgent),
    chromeVersion: parseInt(navigator?.userAgent?.match(/Chrome\/(\d+)\./)?.[1] ?? "0", 10),
    minSupportedChromeVersion: 105,
    isFirefox: /Firefox/i.test(navigator.userAgent),
    firefoxVersion: parseInt(navigator?.userAgent?.match(/Firefox\/(\d+)\./)?.[1] ?? "0", 10),
    minSupportedFirefoxVersion: 121,
    isSafari: /Safari/i.test(navigator.userAgent),
    safariVersion: parseInt(navigator?.userAgent?.match(/Safari\/(\d+)\./)?.[1] ?? "0", 10),
    minSupportedSafariVersion: 15.4,
    isOpera: /Opera/i.test(navigator.userAgent),
    isEdge: /Edge/i.test(navigator.userAgent),
  };
};
