import { ref } from "vue";
import { LocationQueryRaw, useRouter } from "vue-router";

import { auth0 } from "@/services/auth.service.ts";

import { useDashboardStore } from "@/stores";

import { generalObject } from "@@/CommonTypes.ts";
import { PortCallTableTypes } from "@@/PortCallsTypes.ts";
import { DataTableSortMeta } from "primevue/datatable";

import router from "@/router";

export default class DashboardStatisticsController {
  private static _defaultPage = 1;

  static get defaultPage(): number {
    return this._defaultPage;
  }

  private static _defaultPageSize = 10;

  static get defaultPageSize(): number {
    return this._defaultPageSize;
  }

  private static _upcomingPortCallSorts = ref<Array<DataTableSortMeta>>([
    { field: "status", order: -1 },
    { field: "est_arrival_date", order: 1 },
  ]);
  static get upcomingPortCallSorts(): Array<DataTableSortMeta> {
    return this._upcomingPortCallSorts.value;
  }

  static set upcomingPortCallSorts(value: Array<DataTableSortMeta>) {
    this._upcomingPortCallSorts.value = value;
  }

  private static _completedPortCallSorts = ref<Array<DataTableSortMeta>>([
    { field: "status", order: -1 },
    { field: "est_departure_date", order: 1 },
  ]);
  static get completedPortCallSorts(): Array<DataTableSortMeta> {
    return this._completedPortCallSorts.value;
  }

  static set completedPortCallSorts(value: Array<DataTableSortMeta>) {
    this._completedPortCallSorts.value = value;
  }

  private static _filters = ref<LocationQueryRaw>({});
  static get filters() {
    return this._filters.value;
  }

  static set filters(value) {
    this._filterChanged.value = JSON.stringify(this._filters.value) !== JSON.stringify(value);
    this._filters.value = value;
  }

  private static _dateRanges = ref<Array<Record<string, string>>>([
    /*{
      id: "last-month",
      name: "Last month",
    },
    {
      id: "last-6-months",
      name: "Last 6 months",
    },*/
    {
      id: "year-to-date",
      name: "Year to date",
    },
  ]);

  static get dateRanges() {
    return this._dateRanges.value;
  }

  private static _filterChanged = ref<boolean>(false);
  static get filterChanged() {
    return this._filterChanged.value;
  }

  private static _activePortCallTable = ref<PortCallTableTypes>();
  static get activePortCallTable(): PortCallTableTypes | undefined {
    return this._activePortCallTable.value;
  }

  static set activePortCallTable(value: PortCallTableTypes | undefined) {
    console.log("set activePortCallTable", value);
    this._activePortCallTable.value = value;
  }

  private static _upcomingPortCallPage = ref<number>(this._defaultPage);
  static get upcomingPortCallPage(): number {
    return this._upcomingPortCallPage.value;
  }

  static set upcomingPortCallPage(value: number) {
    this._upcomingPortCallPage.value = value;
  }

  private static _upcomingPortCallPageSize = ref<number>(this._defaultPageSize);
  static get upcomingPortCallPageSize(): number {
    return this._upcomingPortCallPageSize.value;
  }

  static set upcomingPortCallPageSize(value: number) {
    this._upcomingPortCallPageSize.value = value;
  }

  private static _completedPortCallPage = ref<number>(this._defaultPage);
  static get completedPortCallPage(): number {
    return this._completedPortCallPage.value;
  }

  static set completedPortCallPage(value: number) {
    this._completedPortCallPage.value = value;
  }

  private static _completedPortCallPageSize = ref<number>(this._defaultPageSize);
  static get completedPortCallPageSize(): number {
    return this._completedPortCallPageSize.value;
  }

  static set completedPortCallPageSize(value: number) {
    this._completedPortCallPageSize.value = value;
  }

  private static _customerPage = ref<number>(this._defaultPage);
  static get customerPage(): number {
    return this._customerPage.value;
  }

  static set customerPage(value: number) {
    this._customerPage.value = value;
  }

  private static _customerPageSize = ref<number>(this._defaultPageSize);
  static get customerPageSize(): number {
    return this._customerPageSize.value;
  }

  static set customerPageSize(value: number) {
    this._customerPageSize.value = value;
  }

  private static _rowsPerPageOptions = ref<Array<number>>([1, 2, 5, 10, 20, 50]);
  static get rowsPerPageOptions(): Array<number> {
    return this._rowsPerPageOptions.value;
  }

  static resetPagination(table: PortCallTableTypes) {
    if (table === "completed") {
      this._upcomingPortCallPage.value = this._defaultPage;
      this._upcomingPortCallPageSize.value = this._defaultPageSize;
    } else {
      this._completedPortCallPage.value = this._defaultPage;
      this._completedPortCallPageSize.value = this._defaultPageSize;
    }
  }

  static getUpcomingPortCallParams() {
    const dashboardStore = useDashboardStore();
    const router = useRouter();

    // Removing empty filters
    const filters = Object.fromEntries(
      Object.entries(DashboardStatisticsController.filters).filter(([, value]) => {
        return !!value?.toString();
      }),
    );
    // const search = new URLSearchParams((filters ?? {}) as unknown as URLSearchParams);

    const local_storage_customer_id = localStorage.getItem("customer_id");
    const parsed_customer_id = local_storage_customer_id
      ? JSON.parse(local_storage_customer_id)
      : null;
    const customer_id = (
      dashboardStore.customer_id ??
      parsed_customer_id ??
      router?.currentRoute?.value?.query?.customer_id ??
      ""
    ).toString();

    const otherParams: generalObject<string | number> = {
      page: DashboardStatisticsController.upcomingPortCallPage || 1,
      page_size: DashboardStatisticsController.upcomingPortCallPageSize || 10,
      customer_id,
    };

    if (otherParams.customer_id) {
      otherParams.customer__in = otherParams.customer_id;
    }
    delete otherParams.customer_id;
    if (!auth0?.user?.value?.can_access_customer) {
      delete otherParams.customer__in;
      dashboardStore.customer_id = null;
      localStorage.removeItem("customer_id");
      localStorage.removeItem("selectedCustomer");
    }

    // Remove page and page_size from filters as they are in optional params
    delete filters.upcoming_page;
    delete filters.upcoming_page_size;

    if (filters.upcoming_search) {
      filters.search = filters.upcoming_search;
      delete filters.upcoming_search;
    }

    if (filters.ports) {
      filters.port__in = filters.ports;
      delete filters.ports;
    }

    if (filters.vessels) {
      filters.vessel__in = filters.vessels;
      delete filters.vessels;
    }

    if (filters.vessel_managers) {
      filters.vessel_manager__in = filters.vessel_managers;
      delete filters.vessel_managers;
    }

    // Sorts as field1,-field2. Where - is descending order
    const ordering = DashboardStatisticsController.upcomingPortCallSorts
      .map((sort) => {
        return `${sort.order === -1 ? "-" : ""}${sort.field}`;
      })
      .join(",");

    return {
      ...otherParams,
      ...filters,
      ordering,
    };
  }

  static getCompletedPortCallParams() {
    const dashboardStore = useDashboardStore();
    const router = useRouter();

    // Removing empty filters
    const filters = Object.fromEntries(
      Object.entries(DashboardStatisticsController.filters).filter(([, value]) => {
        return !!value?.toString();
      }),
    );
    // const search = new URLSearchParams((filters ?? {}) as unknown as URLSearchParams);

    const local_storage_customer_id = localStorage.getItem("customer_id");
    const parsed_customer_id = local_storage_customer_id
      ? JSON.parse(local_storage_customer_id)
      : null;
    const customer_id = (
      dashboardStore.customer_id ??
      parsed_customer_id ??
      router?.currentRoute?.value?.query?.customer_id ??
      ""
    ).toString();

    const otherParams: generalObject<string | number> = {
      page: DashboardStatisticsController.completedPortCallPage || 1,
      page_size: DashboardStatisticsController.completedPortCallPageSize || 10,
      customer_id,
    };

    if (otherParams.customer_id) {
      otherParams.customer__in = otherParams.customer_id;
    }
    delete otherParams.customer_id;
    if (!auth0?.user?.value?.can_access_customer) {
      delete otherParams.customer__in;
      dashboardStore.customer_id = null;
      localStorage.removeItem("customer_id");
      localStorage.removeItem("selectedCustomer");
    }

    // Remove page and page_size from filters as they are in optional params
    delete filters.completed_page;
    delete filters.completed_page_size;

    if (filters.completed_search) {
      filters.search = filters.completed_search;
      delete filters.completed_search;
    }

    if (filters.ports) {
      filters.port__in = filters.ports;
      delete filters.ports;
    }

    if (filters.vessels) {
      filters.vessel__in = filters.vessels;
      delete filters.vessels;
    }

    if (filters.vessel_managers) {
      filters.vessel_manager__in = filters.vessel_managers;
      delete filters.vessel_managers;
    }

    // Sorts as field1,-field2. Where - is descending order
    const ordering = DashboardStatisticsController.completedPortCallSorts
      .map((sort) => {
        return `${sort.order === -1 ? "-" : ""}${sort.field}`;
      })
      .join(",");

    return {
      ...otherParams,
      ...filters,
      ordering,
    };
  }

  static getCustomerID = (): number | null => {
    const dashboardStore = useDashboardStore();

    const customerIDStr = localStorage.getItem("customer_id");
    let customerID = customerIDStr ? +JSON.parse(customerIDStr) : null;

    if (!customerID && router?.currentRoute?.value?.query?.customer_id) {
      customerID = +router.currentRoute.value.query.customer_id;
    }

    if (!customerID) {
      customerID = dashboardStore.customer_id;
    }

    return customerID;
  };
}
