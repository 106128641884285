import {
  fetchCostAndEmissionsPerPort,
  fetchTotalMonthlyCostAndEmissions,
  fetchFDACostAndEmissionsPerPort,
  fetchFDATotalMonthlyCostAndEmissions,
} from "@/api/statistics/statistics.api.ts";

import { StatisticsActions, StatisticsStoreContext } from "@@/stores/modules/StatisticsTypes.ts";
import { ChartParamsRequestType } from "@@/ChartTypes.ts";
import { ref } from "vue";

const minLabels = ref(new Array(4).fill(""));

const actions: StatisticsActions = {
  async fetchCostAndEmissionsPerPortAction(
    this: StatisticsStoreContext,
    params: Partial<ChartParamsRequestType>,
  ) {
    try {
      const { data, status } = await fetchCostAndEmissionsPerPort(params);

      this.$state.cost_and_Emissions_per_port = data;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchTotalMonthlyCostAndEmissionsAction(
    this: StatisticsStoreContext,
    params: Partial<ChartParamsRequestType>,
  ) {
    try {
      const { data, status } = await fetchTotalMonthlyCostAndEmissions(params);

      this.$state.total_monthly_cost_and_emissions = data;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchFDACostAndEmissionsPerPortAction(
    this: StatisticsStoreContext,
    params: Partial<ChartParamsRequestType>,
  ) {
    try {
      const { data, status } = await fetchFDACostAndEmissionsPerPort(params);

      this.$state.fda_cost_and_Emissions_per_port.datasets = data.datasets;

      this.$state.fda_cost_and_Emissions_per_port.labels = [...minLabels.value];
      data.labels.forEach((label: string, index: number) => {
        this.$state.fda_cost_and_Emissions_per_port.labels[index] = label;
      });

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
  async fetchFDATotalMonthlyCostAndEmissionsAction(
    this: StatisticsStoreContext,
    params: Partial<ChartParamsRequestType>,
  ) {
    try {
      const { data, status } = await fetchFDATotalMonthlyCostAndEmissions(params);

      this.$state.fda_total_monthly_cost_and_emissions = data;

      return { data, status };
    } catch (error) {
      console.error(error);
    }
  },
};

export default actions;
