<template>
  <Dropdown
    v-model="currentLocale"
    :options="availableLocales"
    :title="`${t('topbar.placeholder.select_locale')}: ${currentLocale}`"
    class="locale-selector w-7rem"
    data-key="code"
    input-class="py-2"
    option-label="name"
    option-value="code"
    placeholder="Select locale"
  >
    <template #dropdownicon>
      <CaretIcon />
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import i18n from "@/i18n";
import { useI18n } from "vue-i18n";

import Dropdown from "primevue/dropdown";
import { defaultLocal } from "@/locales";
import CaretIcon from "@/components/icons/CaretIcon.vue";

// composable
const { t } = useI18n();

// computed
/**
 * Setting selected locale to localStorage
 */
const currentLocale = computed({
  get: () => i18n.global?.locale?.value ?? defaultLocal,
  set: (value) => {
    i18n.global.locale.value = value;
    localStorage.setItem("locale", value);
  },
});

/**
 * Locals are available to choose from dropdown
 */
const availableLocales = computed(
  () =>
    i18n.global.availableLocales.map((locale) => ({
      name: t(`topbar.locale.${locale}`),
      code: locale,
    })) ?? [],
);
</script>

<style lang="scss">
.locale-selector {
  .p-dropdown-trigger {
    max-width: 3rem !important;
    width: unset;
  }
}
</style>
