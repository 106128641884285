import { ref } from "vue";
import { useResizeObserver } from "@vueuse/core";
import router from "@/router";
import * as jose from "jose";
import { jwtDecode } from "jwt-decode";

import { auth0, getAuth0Email } from "@/services/auth.service.ts";
import { isAgent } from "@/permission.ts";
import AuthController from "@/controllers/AuthController.ts";
import {
  BackendAuth0State,
  generalObject,
  NumberFormatterOptionsType,
  ResizeObserverOptionsType,
} from "@@/CommonTypes.ts";
import { NavigationGuardNext, RouteLocationNormalizedLoaded } from "vue-router";
import { JWTPayload } from "jose";

const debounceTimeout = ref<ReturnType<typeof setTimeout>>();
const debounceAfter = ref<number>();
const defaultDebounceAfter = 380;

export const debounce = (
  callback: { (argument: { search: string }) },
  options: { after?: number; search?: string } = { search: "" },
) => {
  const search = options.search ?? "";

  debounceAfter.value = options.after ?? defaultDebounceAfter;

  clearTimeout(debounceTimeout.value);

  debounceTimeout.value = setTimeout(() => {
    callback({
      search,
    });
  }, debounceAfter.value || defaultDebounceAfter);
};

/**
 * Set the current module name in the body tag. It'll help to add the module specific css.
 */
export const setCurrentModuleName = () => {
  const name = (router.currentRoute.value.name ?? "").toString();
  if (!name) {
    return;
  }

  const body = document.querySelector("body");
  if (!body) {
    return;
  }

  body.dataset.routeName = name;
};

/**
 * Set the user role in the body tag. It'll help to add the module specific css.
 */
export const setUserRole = () => {
  const role = (auth0?.user?.value?.user_role || "").toString();
  if (!role) {
    return;
  }

  const body = document.querySelector("body");
  if (!body) {
    return;
  }

  body.dataset.userRole = role;
};

export const arrayUniqueByKey = <T>(array: T[], key: string | number) => [
  ...new Map(array.map((item) => [item[key], item])).values(),
];

export const getIfScrollBottom = (target: HTMLElement) => {
  return target.scrollHeight - (target.scrollTop + target.offsetHeight);
};

export const getAPIEndPoint = (urlStr: string | URL) => {
  const url = new URL(urlStr);

  const pathname = url.pathname; // Replace with your actual URL

  // Define a regular expression pattern to match the version number (X)
  const versionPattern = /\/api\/v(\d+)\//;

  // Use the regular expression pattern to extract the version number
  const match = pathname.match(versionPattern);

  // Check if a match was found
  if (match) {
    const versionNumber = match[1]; // Extract the version number (X)
    console.log("Version Number:", versionNumber);
  } else {
    console.log("Version number not found in the URL.");
  }
};

export const getSlug = (str: string | number): string => {
  str = str.toString();
  str = str.trim(); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;",
    to = "aaaaeeeeiiiioooouuuunc------";

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const getDashboardRouterName = (): string => {
  return isAgent() ? "AgentDashboard" : "Dashboard";
};

export const templateMultiStringReplace = <T extends string>(
  object: generalObject<string>,
  string: T,
): T => {
  const entries = Object.entries(object);
  entries.forEach(([key, value]) => {
    const find = "{" + key + "}";
    const regExp = new RegExp(find, "g");
    string = string.replace(regExp, value as T) as T;
  });
  return string;
};

export const downloadBlob = (content: string, filename: string, contentType: string) => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
};

export const twoLineText = (target: HTMLElement, targetWidth: number) => {
  target.style.setProperty("white-space", "nowrap");
  const targetScrollWidth = target.scrollWidth;
  target.style.setProperty("width", `${targetWidth}px`);

  const inPercent = (targetScrollWidth * 100) / targetWidth;

  target.style.setProperty(
    "max-width",
    `${inPercent / 2 + (inPercent >= 75 ? 0 : inPercent / 10)}%`,
  );
  target.style.setProperty("white-space", "");
  target.style.setProperty("width", "");
};

export const twoLineTextPreWork = (target: HTMLElement, iconClass: string, titleClass: string) => {
  if (!target) {
    return;
  }

  const statisticsTitle = target.querySelector(`.${titleClass}`) as HTMLElement;
  if (!statisticsTitle) {
    return;
  }

  const statisticsIcon = target.querySelector(`.${iconClass}`) as HTMLElement;
  if (!statisticsIcon) {
    return;
  }

  const statisticsTitleWidth =
    target.clientWidth -
    (parseInt(getComputedStyle(target)?.gap || "0") +
      parseInt(getComputedStyle(statisticsIcon)?.width || "0"));

  twoLineText(statisticsTitle, statisticsTitleWidth);
};

export const resizeObserver = (target: HTMLElement, options: ResizeObserverOptionsType) => {
  useResizeObserver(target?.closest("body"), (entries) => {
    const entry = entries[0];
    const { width } = entry.contentRect;

    if (!width) {
      return;
    }

    debounce(
      () => {
        if (!target) {
          return;
        }

        const parentTargetList = target?.querySelectorAll(
          `.${options.parentClass}`,
        ) as unknown as NodeListOf<HTMLElement>;

        parentTargetList?.forEach((parentTarget) => {
          if (!parentTarget) {
            return;
          }

          twoLineTextPreWork(parentTarget, options.iconClass, options.titleClass);
        });
      },
      {
        after: options.after ?? 500,
      },
    );
  });
};

export const convertNumberToAlphabetic = (_num: number, char = ""): string => {
  if (_num <= 0) {
    console.log("Please use number greater than 0.");
    return char;
  }

  const divisor = 26;
  const num = _num - 1; // Starts from 0

  const reminder = num % divisor;
  const quotient = Math.floor(num / divisor);

  char = String.fromCharCode("A".charCodeAt(0) + reminder) + char;

  if (quotient > divisor) {
    return convertNumberToAlphabetic((quotient - 1) * divisor, char);
  } else if (quotient) {
    return convertNumberToAlphabetic(quotient, char);
  }

  return char;
};

export const numberFormatter = (
  num: number,
  options: NumberFormatterOptionsType = {
    digits: 2,
    commaSeparated: true,
    locales: "en-US",
  },
): string => {
  if (num === 0) {
    return num.toFixed(options.digits);
  }

  const factor = Math.pow(10, options.digits);
  const roundedNum = Math.round(num * factor) / factor;

  let numberLocalString = roundedNum.toFixed(options.digits);

  if (options.commaSeparated) {
    numberLocalString = (+numberLocalString).toLocaleString(options.locales);
  }

  const partAfterDecimal = numberLocalString.split(".")[1] || "";
  if (partAfterDecimal.length === 0) {
    return numberLocalString + "." + "0".repeat(options.digits);
  }

  const forceDigits = options.digits - partAfterDecimal.length;
  if (forceDigits > 0) {
    return numberLocalString + "0".repeat(forceDigits);
  }

  return numberLocalString;
};

export const checkBackendStateAndActAccording = (
  to: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext,
) => {
  AuthController.checkingBackendState = true;
  // http://127.0.0.1:8000/dashboard/agent?clearLocalStorage=1&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNoYXJtaW5fYWdlbnQwMUB5b3BtYWlsLmNvbSIsImlhdCI6MTY5ODkyNzg2Nn0.m86nea3Vb6vtF0Ps1ruSJQyOqC4KcoUPgCdpJc8-aF4
  if (to.name === "Auth0Redirect") {
    return false;
  }

  const ret = true;

  const auth0Email = getAuth0Email();
  if (!auth0Email) {
    next({ name: "Login" });
    AuthController.checkingBackendState = false;
    return ret;
  }

  const encodedState = to.query?.state?.toString();
  const decodedState = jwtDecode<BackendAuth0State>(encodedState ?? "");

  const email = decodedState?.email;
  delete to.query.state;
  delete to.query.clearLocalStorage;

  if (email !== auth0Email) {
    const search = new URLSearchParams(to.query as unknown as string);
    const url = to.path + (search.toString() ? "?" + search.toString() : "");
    const intended = JSON.stringify(url);
    localStorage.setItem("intended", intended);

    next({ name: "Logout" });
    AuthController.checkingBackendState = false;
    return ret;
  }

  next({ path: to.path, query: to.query });
  AuthController.checkingBackendState = false;

  return ret;
};

export const encodeJWT = async (payload: JWTPayload | undefined) => {
  const key = new TextEncoder().encode(import.meta.env.VITE_APP_JWT_TOKEN_KEY);
  const alg = "HS256";

  return await new jose.SignJWT(payload).setProtectedHeader({ alg }).sign(key);
};
