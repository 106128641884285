<template>
  <BaseAuthMessageDialog :show-loading-string="true">
    <template #header></template>
    <template #title>Taking you to the preferred dashboard</template>
    <template #description></template>
  </BaseAuthMessageDialog>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";

import BaseAuthMessageDialog from "@/components/reusables/BaseAuthMessageDialog.vue";

import { getDashboardRouterName } from "@/utilities.ts";

const router = useRouter();

setTimeout(() => {
  const to = {
    name: getDashboardRouterName(),
  };

  router.push(to);
});
</script>
