import { auth0 } from "@/services/auth.service.ts";
import { USER_ROLES } from "@@/AuthTypes.ts";

const dashboardFilters = ["Port", "Vessel", "Vessel Manager"];

export const dashboardFilterPermissions = {
  "System Administrator": dashboardFilters,
  Agent: dashboardFilters,
  "Vessel Manager": ["Port", "Vessel"],
  "ESG Manager": dashboardFilters,
};

export const isVesselManager = (): boolean => {
  return auth0?.user?.value?.user_role === USER_ROLES["Vessel Manager"];
};

export const isAgent = (): boolean => {
  return auth0?.user?.value?.user_role === USER_ROLES["Agent"];
};

export const isESGManager = (): boolean => {
  return auth0?.user?.value?.user_role === USER_ROLES["ESG Manager"];
};

export const isVesselManagerOrAgent = (): boolean => {
  return isVesselManager() || isAgent();
};

export const isVesselManagerOrESGManager = (): boolean => {
  return isVesselManager() || isESGManager();
};

export const hasRole = (role: USER_ROLES) => {
  return auth0?.user?.value?.user_role === role;
};

export const hasAnyRole = (roles: USER_ROLES[]) => {
  const userRole = auth0?.user?.value?.user_role;
  if (!userRole) return false;
  return roles.includes(userRole);
};
