import { useSiteStateStore } from "@/stores";

export default class LoaderController {
  private static _loader_counter = 0;

  // Initilizing the default values for loader object
  private static _is_loading = false;

  static get is_loading(): boolean {
    return this._is_loading;
  }

  static set is_loading(value: boolean) {
    this._is_loading = value;
  }

  /**
   * Responsible for increasing the loader counter
   * @returns {void}
   */
  static showLoader(): void {
    this._loader_counter++;
    this._loadingStatus();
  }

  /**
   * Responsible for decresing the loader counter to 0
   * @returns {void}
   */
  static hideLoader(): void {
    if (this._loader_counter > 0) {
      this._loader_counter--;
    }
    this._loadingStatus();
  }

  private static _loadingStatus() {
    const siteStateStore = useSiteStateStore();

    if (this._loader_counter > 0) {
      this._is_loading = true;
    } else {
      this._is_loading = false;
    }

    siteStateStore.setIsLoading(this._is_loading);
  }
}
