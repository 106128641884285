import { useAxios } from "@/services/axios.service.ts";

import { HTTP_DATA } from "@/api/statistics/httpEnums.ts";

import { ChartParamsRequestType } from "@@/ChartTypes.ts";

import { StatisticsResponse } from "@@/StatisticsTypes.ts";

const fetchCostAndEmissionsPerPort = async (params: Partial<ChartParamsRequestType>) =>
  useAxios().get(`${HTTP_DATA.GET_COST_AND_EMISSIONS_PER_PORT}`, { params });

const fetchTotalMonthlyCostAndEmissions = async (params: Partial<ChartParamsRequestType>) =>
  useAxios().get(`${HTTP_DATA.GET_TOTAL_MONTHLY_COST_AND_EMISSIONS}`, { params });

const fetchFDACostAndEmissionsPerPort = async (params: Partial<ChartParamsRequestType>) =>
  useAxios().get(`${HTTP_DATA.GET_FDA_COST_AND_EMISSIONS_PER_PORT}`, { params });

const fetchFDATotalMonthlyCostAndEmissions = async (params: Partial<ChartParamsRequestType>) =>
  useAxios().get(`${HTTP_DATA.GET_FDA_TOTAL_MONTHLY_COST_AND_EMISSIONS}`, { params });

const fetchStatistics= async (params: Partial<ChartParamsRequestType>) =>{
  const resp = await useAxios().get<StatisticsResponse>(`${HTTP_DATA.GET_FDA_STATISTICS}`, { params });
  return resp.data;
};

export {
  fetchCostAndEmissionsPerPort,
  fetchTotalMonthlyCostAndEmissions,
  fetchFDACostAndEmissionsPerPort,
  fetchFDATotalMonthlyCostAndEmissions,
  fetchStatistics
};
