import { defineStore } from "pinia";

import state from "@/stores/statistics/statistics.state";
import getters from "@/stores/statistics/statistics.getters";
import actions from "@/stores/statistics/statistics.actions";

import { StatisticsStoreDefinition } from "@@/stores/modules/StatisticsTypes.ts";

const useStatisticsStore: StatisticsStoreDefinition = defineStore("statistics", {
  state,
  getters,
  actions,
});

export default useStatisticsStore;
