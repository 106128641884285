<template>
  <BaseJsonPretty :data="user" />
  <hr />
  <BaseJsonPretty :data="idTokenClaims" />
  <hr />
  <BaseJsonPretty :data="useAuth0()" />
</template>

<script lang="ts" setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { user, idTokenClaims } = useAuth0();
</script>
