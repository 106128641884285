import type { RouteRecordRaw } from "vue-router";
import DashboardLayout from "@/layouts/DashboardLayout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/dashboard/DashboardView.vue"),
        meta: {
          title: "Customer Dashboard",
        },
      },
      {
        path: "agent",
        name: "AgentDashboard",
        component: () => import("@/views/dashboard/AgentDashboardView.vue"),
        meta: {
          title: "Agent Dashboard",
        },
      },
    ],
  },
];

export default routes;
